export default {
    type: 'object',
    title: 'BILDE/FIL-TEKSTER, LISENS OG STIKKORD',
    properties: {
        title: {
            type: 'string',
            title: 'Tittel: Vises i adminklient'
        },
        description: {
            type: 'string',
            title: 'Bildetekst/Dokumentbeskrivelse (norsk)'
        },
        photographer: {
            type: 'string',
            title: 'Fotograf'
        },
        licenses: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
            title: '',
            default: [],
            items: {
                type: 'string',
                title: 'Velg lisens',
                enum: [
                    'CC pdm',
                    'CC CC0',
                    'CC by',
                    'CC by-sa',
                    'CC by-nc',
                    'CC by-nc-sa',
                    'CC by-nd',
                    'CC by-nc-nd',
                    'copyright'
                ],
                enumNames: [
                    'Falt i det fri (PDM)',
                    'Ingen rettigheter forbeholdt (CC0)',
                    'Kun navngivelse (BY)',
                    'Navngivelse, del på samme vilkår (CC BY-SA)',
                    'Navngivelse, ikke kommersiell (CC BY-NC)',
                    'Navngivelse, ikke kommersiell, ingen bearbeidelse (CC BY-NC-SA)',
                    'Navngivelse + Ingen bearbeidelse (BY-ND)',
                    'Navngivelse, ikke kommersiell, ingen bearbeidelser (CC BY-NC-ND)',
                    'Rettighetsbelagt (Opphavsrett)',
                ],
                default: ''
            }
        },
        tags: {
            type: 'array',
            title: '',
            maxItems: 1,
            minItems: 1,
            default: [],
            items: {
                type: 'string',
                title: 'Stikkord, kommaseparer hvis flere',
                default: ''
            }
        }
    }
};
