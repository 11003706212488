const contribution = {
    type: 'object',
    properties: {
        headerImage: { type: 'string' },
        id: { type: 'integer' },
        dimu_unique_id: { type: 'string' },
        legacyId: { type: 'integer' },
        title: { type: 'string' },
        type: {
            type: 'string',
            enum: [ 'contribution' ],
            enumNames: [ 'Legg til relatert bidrag' ],
            default: 'contribution'
        }
    }
};

export default contribution;
