import { USER_ACCESS } from '../actions/userAccess';

const initialState = {
    root: false,
    statistics: false,
    inbox: false,
    editor: false
};

const userAccess = (state = initialState, action) => {
    if (action && action.type === USER_ACCESS) {
        return { ...action.roleAccess };
    }

    return { ...state };
};

export default userAccess;
