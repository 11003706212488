import React, {useState} from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
// import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: 166,
        [`& fieldset`]: {
            borderRadius: 8,
        },

    },
    input: {
        '& .MuiSelect-outlined': {
            /* background: '#E9EFF2;'*/
            borderRadius: 8,
            height: 12,
            minHeight: 12,
            lineHeight: 0.8
        }
    },
    menu: {
        width: 100,
    },
    wrapper: {
        display: "flex",
        /* justifyContent: "space-between",*/
        marginBottom: 16,
        marginTop: 26.5,
        '& .foundNumOfItems': {
            display: 'flex',
            alignItems: 'center',
            '@media (min-width: 280px) and (max-width: 320px)': {
                marginRight: 0,
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
                marginLeft: 'auto'
            },
        },
        '& .wrapperFormGroup': {
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                order: 3,
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 16
            }
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            marginTop: 0,
            paddingLeft: 8
        },
        '& .textFieldWrapper': {
            marginLeft: 'auto',
            [theme.breakpoints.down('xs')]: {
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 16
            },
            '@media (min-width: 280px) and (max-width: 320px)': {
                marginLeft: -10,
                marginRight: 0,
            },
        }
    },

}));

const tags = [
    {
        value: 'Aktuelt',
        label: 'Aktuelt først',
    },
    {
        value: 'Nyeste først',
        label: 'Nyeste først',
    }
];


export const RadioButtonFilter = ({onChangeForm, form, fetching, total}) => {
    const classes = useStyles(),
        [tag, setTag] = useState('Nyeste først');

    const handleChangeRadio = (event) => {
        onChangeForm({...form, radioButtonValue: event.target.value});
    };

    const handleChangeTag = event => {
        setTag(event.target.value);
    };

    const foundResources = () => {
        if (fetching) {
            return <CircularProgress color="primary" size={25}/>
        } else if (total) {
            return total;
        }

        return 0;
    };

    return (
        <div className={classes.wrapper}>
            <div className="foundNumOfItems">
                <h3 style={{marginRight: 5}}>{foundResources()} treff: </h3>
            </div>
            <div className="wrapperFormGroup">
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={form.radioButtonValue === 'article'}
                                onChange={handleChangeRadio}
                                name="checkedArticle"
                                color="primary"
                                value="article"
                            />
                        }
                        label="Ressurser"
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                checked={form.radioButtonValue === 'assignment'}
                                onChange={handleChangeRadio}
                                name="checkedAssignment"
                                color="primary"
                                value="assignment"
                            />
                        }
                        label="Oppgaver"
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                checked={form.radioButtonValue === 'contribution'}
                                onChange={handleChangeRadio}
                                name="checkedContribution"
                                color="primary"
                                value="contribution"
                            />
                        }
                        label="Bidrag"
                    />
                </FormGroup>
            </div>
            <div className="textFieldWrapper">
                <TextField
                    id="outlined-select"
                    select
                    className={classes.textField}
                    value={tag}
                    onChange={handleChangeTag}
                    InputLabelProps={{shrink: false}}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        }
                    }}
                    InputProps={{className: classes.input}}
                    variant="outlined"
                    margin="normal"
                    /*size="small"*/
                >
                    {tags.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        </div>
    );
};
