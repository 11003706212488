import React, { useState } from 'react';
import { List, ListItem, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { kitFetchExternal } from '@ekultur/header-state';

import config from 'common/config';

let timer;

export const PlaceQueryWidget = props => {
    const [places, setPlaces] = useState(props.value ? [{title_expanded: props.value}] : []),
        [query, setQuery] = useState(''),
        [anchorEl, setAnchorEl] = useState(null),
        [menuOpen, setMenuOpen] = useState(false),
        [selectedIndex, setSelectedIndex] = useState(0),
        { registry, formContext, id } = props,
        { TextWidget } = registry.widgets,
        { t } = useTranslation(['dm.article'], { useSuspense: false }),
        schema = {
            ...props,
            type: 'string',
            value: query
        };

    const _onClick = e => {
        if (!anchorEl) {
            setAnchorEl(e.currentTarget);
        }
    }

    const _onChange = q => {
        setQuery(q);

        if (timer) {
            clearTimeout(timer);
            console.log('cleared timeout. q: ', q);
        }

        if (q) {
            timer = setTimeout(() => {
                console.log('setExecutedSearch: ', q);
                kitFetchExternal(`${config.get('placeApi')}?q=${q}`)
                    .then(data => {
                        setPlaces(data);
                        setMenuOpen(true);
                    })
                    .catch(error => console.error(error))
            }, 2000);
        }
    };

    const handleClickListItem = event => {
        event.preventDefault();
        event.stopPropagation();
        setMenuOpen(query && !menuOpen);
    };

    const handleMenuItemClick = (event, index) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex(index);
        setMenuOpen(false);
        formContext.onAddPlace(places[index], 'content.local_belonging.0');
    };

    const handleClose = event => {
        event.preventDefault();
        event.stopPropagation();
        setMenuOpen(false);
    };

    return (
        <>
            <div onClick={_onClick}>
                <TextWidget {...schema} label={t('place_search', 'Søk etter stedsnavn')} onChange={_onChange} />
            </div>
            {
                !!places.length && (
                    <>
                        <List component="nav" aria-label="Filter">
                            <ListItem
                                key="place-select"
                                // className={classes.filter}
                                button
                                aria-haspopup="true"
                                aria-controls="lock-menu"
                                aria-label="Filter"
                                onClick={handleClickListItem}
                            >
                                {/* <ListItemText primary={schema.enumNames[selectedIndex]} /> */}
                                {places[selectedIndex] && places[selectedIndex].title_expanded || t('place_undefined', 'Sted er ikke definert')}
                            </ListItem>
                        </List>
            
                        <Menu
                            id="lock-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={menuOpen}
                            onClose={handleClose}
                        >
                            {places.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    selected={index === selectedIndex}
                                    onClick={event => handleMenuItemClick(event, index)}
                                >
                                    {option.title_expanded}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                )
            }
        </>
    );
};
