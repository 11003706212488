import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    chipWrapper: {
        display: 'flex',
        flexFlow: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 16,
        paddingRight: 16,
        '& > *': {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1),
            paddingLeft: 8,
            paddingRight: 16,
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: "wrap",
            marginTop: 180,
            justifyContent: 'flex-start'
        },
    }
}));


export const FilterChipRow = ({ filters, handleDeleteFilter }) => {
    const classes = useStyles();

    const renderFilterChip = fltr => {
        return (
            <Chip
                key={fltr.id}
                label={fltr.title}
                color="primary"
                onDelete={() => handleDeleteFilter(fltr.id)}
            />
        );
    };

    return (
        <div className={classes.chipWrapper}>
            {filters.map(renderFilterChip)}
        </div>
    );
};
