import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#006C85'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    }
}));

const DeleteDialog = ({ isDeleteDialogOpen, onDeleteClose, title }) => {
    const classes = useStyles();
    return (
        <Dialog
            open={isDeleteDialogOpen}
            onClose={onDeleteClose}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onDeleteClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box m={4}>
                Er du sikkert på at du vil slette bildet?
                <Box mt={4} style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button variant="contained" onClick={onDeleteClose}>Avbryt</Button>
                    <Button variant="contained" style={{ color: 'red' }}>Slett</Button>
                </Box>
            </Box>
        </Dialog>
    )
};

export default DeleteDialog
