import React from 'react';
import { Trans } from 'react-i18next';

import headerBlock from './ownerHeaderBlock';
import logoDescBlock from './ownerLogoDescBlock';

export default {
    type: 'object',
    title: <Trans ns="dm.article" i18nKey="section_header">Header</Trans>,
    properties: {
        type: { enum: ['article__head'] },
        _editor: {
            '$ref': '#/definitions/_editor'
        },
        /* blocks: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
            // title: 'Tittel og ingress',
            title: null,
            items: headerBlock
        } */
        blocks: {
            type: 'array',
            maxItems: 2,
            minItems: 2,
            title: null,
            items: {
                type: 'object',
                required: ['type'],
                properties: {
                    type: {
                        type: 'string',
                        readOnly: true,
                        // title: null,
                        enum: [
                            'startpageConfig',
                            'startpageLogoDescription'
                        ]
                    }
                },
                dependencies: {
                    type: {
                        oneOf: [
                            headerBlock,
                            logoDescBlock
                        ]
                    }
                }
            }
        }
    }
};