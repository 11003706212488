import React from 'react';
import Fade from "@material-ui/core/Fade";
import LinearProgress from "@material-ui/core/LinearProgress";

export const ProgressIndicator = () => {
    return (
        <div>
            <Fade in={true} style={{ transitionDelay: '800ms' }} unmountOnExit={true}>
                <LinearProgress />
            </Fade>
        </div>
    )
};