import { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';

import { useDeepCompareEffect } from 'react-use';

import { buildQuery } from '../../helpers/buildQuery';

let timers = { filters: null, field: null, typing: null };

export const DmSearchItemTemplate = ({ props, classes }) => {
    const { properties } = props,
        [expanded, setExpanded] = useState(false),
        qEl = properties.find(x => x.name === 'query'),
        sEl = properties.find(x => x.name === 'settings'),
        fltrEl = properties.find(x => x.name === 'filter'),
        q = qEl.content.props.formData,
        fltr = fltrEl.content.props.formData || {},
        [filter, setFilter] = useState(fltr),
        [changeFrom, setChangeFrom] = useState();

    const getFilterValueFromQuery = (qArr, pos, int = false) => {
        const fStr = qArr.splice(pos, 1)[0],
            f = fStr.split(':')[1] || 0;

        return int ? parseInt(f) : f;
    };

    // deep compare filters to avoid rerenders
    useDeepCompareEffect(() => {
        // console.log(`filters are updated: ${fltr}`);
        if (fltr){
            setFilter(fltr);
            setChangeFrom('fltr');
        }
    }, [fltr]);

    useEffect(() => {
        // console.log(`q is updated: ${q}`);
        if (q) {
            setChangeFrom('q');
        }
    }, [q]);

    useEffect(() => {
        if (!changeFrom) {
            return;
        }

        console.log(`changeFrom: ${changeFrom}`);

        if (changeFrom === 'q') {
            if (timers.filters) {
                clearTimeout(timers.filters);
            }

            timers.filters = setTimeout(() => {
                // console.log(`q is updated: ${q}`);
                const qArr = q ? q.split(' ') : [],
                    fltrProps = fltrEl.content.props,
                    fltrEntries = Object.entries(fltrEl.content.props.schema.properties);

                let newFltr = { ...fltrProps.formData };

                for (const [name, settings] of fltrEntries) {
                    const pos = qArr.findIndex(x => x.split(':')[0] === name);

                    if (pos !== -1) {
                        newFltr[name] = getFilterValueFromQuery(qArr, pos, settings.type === 'integer');
                    } else {
                        newFltr[name] = settings.type === 'integer' ? 0 : '';
                    }
                }

                // console.log(`committing change to fltr`);
                setChangeFrom();
                fltrProps.onChange(newFltr);
            }, 500);
        } else {
            if (timers.field) {
                clearTimeout(timers.field);
            }
        
            timers.field = setTimeout(() => {
                const newQ = buildQuery(q, filter);
                console.log(`committing change to q`);
                setChangeFrom();
                qEl.content.props.onChange(newQ);
            }, 100);
        }

    }, [changeFrom, q, filter, fltrEl.content.props, qEl.content.props]);

    if (typeof q === 'undefined') {
        return null;
    }

    return (
        <div className={`${classes.searchItem} greyBox`}>
            {!expanded && (
                <div className="titleWrapper" onClick={() => setExpanded(true)}>
                    <SearchIcon />
                    <h5>«{buildQuery(q, fltr)}»</h5>
                </div>
            )}
            {expanded && (
                <div className="fieldWrapper">
                    {properties.map(el => {
                        if (!['type', 'settings'].includes(el.name)) {
                            return el.content;
                        }

                        return null;
                    })}
                    <Button
                        className="ok"
                        onClick={() => setExpanded(false)}
                        color="secondary">
                        Lukk søk
                    </Button>
                </div>
            )}
            {sEl && sEl.content}
        </div>
    );
};
