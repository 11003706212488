import React, {Fragment} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Slider from 'react-slick';
import Markdown from 'react-markdown/with-html';

import { Dm } from './Dm';
import { License } from './License';
import setImageStyle from 'admin/src/Article/imageFilters/filterStyle';
import { svgToSrc } from 'admin/src/Article/helpers/svgToSrc';

import 'admin/src/Article/slick_styles/slick.css';
import 'admin/src/Article/slick_styles/slick-theme.css';
// import { ShareResources } from 'portal/src/content/shareResource';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
    articleBody: {
        paddingBottom: spacing(1),
        marginRight: 'auto',
        marginLeft: 'auto',
        fontSize: 18,
        /*fontSize: 20,*/
        lineHeight: 1.5,
        /*  maxWidth: 500,*/
        maxWidth: 600,
        [breakpoints.down('xs')]: {
            marginRight: 7,
            marginLeft: 7,
            paddingLeft: 7,
            paddingRight: 7,
            fontSize: 16,
        },
        '& a': {
            color: '#006B8D'
        },
        '& .article__bodytext': {
            '& .row': {
                '&.large': {
                    width: '100%'
                },
                '&.medium': {
                    width: '50%',
                },
                '&.small': {
                    width: '25%'
                },
                '&.row--img-left': {
                    float: 'left',
                    maxWidth: '50%',
                    paddingTop: spacing(1),
                    paddingRight: spacing(2),
                    paddingBottom: spacing(2)
                },
                '&.row--img-right': {
                    float: 'right',
                    maxWidth: '50%',
                    paddingTop: spacing(1),
                    paddingLeft: spacing(2),
                    paddingBottom: spacing(2)
                }
            },
            '& .iframe[Attributes Style]': {
                width: '500px',
                height: '250px',
                borderTopWidth: '0px',
                borderRightWidth: '0px',
                borderBottomWidth: '0px',
                borderLeftWidth: '0px',
            },
            '& blockquote': {
                margin: 0,
                '& > p: before': {
                    content: '"«"'
                },
                '& > p:after': {
                    content: '"»"'
                }
            }
        },
        '& .section--auto': {
            margin: '1.5em inherit !important'
        },
        '& .row__grid': {
            '& .row--grid': {
                paddingTop: spacing(1),
                paddingRight: spacing(2),
                paddingBottom: spacing(1),
                boxSizing: 'border-box',
            },
            '&.article__bodytext:after': {
                content: '""',
                display: 'table',
                clear: 'both'
            },
            '&.article__media': {
                display: 'flex',
                flexFlow: 'wrap',
                '& video': {
                    width: '100%'
                }
            },
            '& .large': {
                width: '100%'
            },
            '& .medium': {
                width: '50%',
            },
            '& .small': {
                width: '25%'
            }
        },
        '& img': {
            width: '100%'
        },
        '& .dm': {
            '& h2': {
                width: '704px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },
        '.anchorMode & [data-unique-key]': {
            '&:hover': {
                background: 'yellow'
            },
            '&.active': {
                background: 'green'
            },
            '& *': {
                pointerEvents: 'none'
            }
        },
        '& .shareResources': {
            marginBottom: 80
        }
    },
    slideshow: {
        '& .slick-prev': {
            'z-index': 1
        }
    },
    sliderItem: {
        paddingBottom: '56.25%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: '#808080'
    },
    visstedu: {
        backgroundColor: '#06c1ed',
        padding: '7px',
        color: 'white'
    },
}));


export const ArticleBody = ({ blocks, anchorObj }) => {
    const classes = useStyles();

    const renderBodyText = block => {
        const { resources } = block;
        return (
            <div className={`row__grid ${block.type}`}>
                {
                    (block.type === 'article__bodytext' && resources.mediaList && resources.mediaList.length)
                        ? renderMediaItem(block, resources.mediaList[0], 0)
                        : null
                }
                {
                    (block.type === 'article__visstedu' &&
                        <div>
                            {
                                (!!block.title && !block.titleHidden) &&
                                <h2><Markdown source={block.title} escapeHtml={false} /></h2>
                            }
                            <div className={classes.visstedu}>
                                <Markdown source={block.bodytext} escapeHtml={false} />
                            </div>
                        </div>
                    )
                }
                {
                    (block.type === 'article__bodytext' &&
                        <div className="article__bodytext">
                            {
                                (!!block.title && !block.titleHidden) &&
                                <h2><Markdown source={block.title} escapeHtml={false} /></h2>
                            }
                            <Markdown source={block.bodytext} escapeHtml={false} />
                        </div>
                    )
                }
            </div>
        );
    };

    const renderMedia = block => {
        const { settings } = block;

        if (!block.resources || !block.resources.mediaList || !block.resources.mediaList.length) {
            return null;
        }

        const renderSlideShow = (item, index) => {
            return (
                <Fragment key={index}>
                    <figure
                        className={`slick ${classes.sliderItem}`}
                        // focuspoint
                        // data-focus="false"
                        // data-focus-x="{{ item.media.focuspoint.x }}"
                        // data-focus-y="{{ item.media.focuspoint.y }}"
                        // data-focus-w="{{ item.media.focuspoint.imgattr.width }}"
                        // data-focus-h="{{ item.media.focuspoint.imgattr.height }}"
                        // id="block-{{ block.unique_key }}--media--{{ $index }}-media"
                        style={{
                            ...setImageStyle(item.media),
                            backgroundImage: `url(${(item.mimetype && item.mimetype.indexOf('png') > -1) ? item.url + '?mediaType=image/png' : item.url})`
                        }}
                    >
                    </figure>
                    <div className="module__content">
                        {item.local && item.local.description && item.local.description !== '' &&
                            <span className="media__caption">{item.local.description}</span>}
                        <span className="media__credit">{item.local && item.local.credit}</span>
                        {item.licenses && item.licenses[0] && <License code={item.licenses[0]} />}
                    </div>
                </Fragment>
            );
        };

        if (settings.layout === 'slideshow') {
            const conf = {
                infinite: false,
                variableWidth: false,
                adaptiveHeight: false,
                arrows: true,
                initialSlide: 0,
                pauseOnHover: true,
                pauseOnFocus: false
            };

            return (
                <div className={classes.slideshow}>
                    <Slider {...conf}>
                        {block.resources.mediaList.map((m, index) => renderSlideShow(m, index))}
                    </Slider>
                </div>
            )
        }

        return (
            <div className={`row__grid ${block.type}`}>
                {block.resources.mediaList.map((item, index) => renderMediaItem(block, item, index))}
            </div>
        );
    };

    const renderMediaItem = (block, item, index) => {
        const desc = (item.local && item.local.description) || item.description,
            cred = item.local && item.local.credit,
            license = item.licenses && item.licenses[0],
            pos = (block.settings && block.settings.layout) || '',
            size = (item.settings && item.settings.size) || '';

        const renderItem = item => {
            switch (item.type) {
                case 'video':
                    return (
                        <video controls>
                            <source src={item.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    );
                case 'document':
                    return (
                        <a href={item.url} target="_blank" rel="noreferrer">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg" alt="PDF" />
                            <span>{item.title}</span>
                        </a>
                    );
                default:
                    return (
                        <figure className="article__bodytext">{
                            item.mimetype === 'image/svg+xml' && item.source
                                ? <img className="svg" src={svgToSrc(item.source)} alt={desc} style={setImageStyle(item.media)} />
                                : <img src={item.url} alt={desc} style={setImageStyle(item.media)} />
                        }
                        </figure>
                    );
            }
        };

        return (
            <div key={index} className={`row row--${pos} ${size}`}>
                {renderItem(item)}
                {desc && desc !== '' && <span className="media__caption">{desc}</span>}
                {cred && <span className="media__credit">{cred}</span>}
                {license && <License code={license} />}
            </div>
        );
    };

    const renderBlock = (block, index) => {
        const id = `@section_1.block_${index}`;

        if (block.settings.visibility !== 'visible') {
            return null;
        }

        if (block.type === 'article__bodytext') {
            return (
                <section
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`article__bodytext-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`article__content${anchorObj.value === block._unique_key ? ' active' : ''}`}
                >
                    {renderBodyText(block)}
                </section>
            );
        } else if (block.type === 'article__visstedu') {
            return (
                <section
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`article__visstedu-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`article__content${anchorObj.value === block._unique_key ? ' active' : ''}`}
                >
                    {renderBodyText(block)}
                </section>
            );
        } else if (block.type === 'article__media') {
            return (
                <div
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`article__media-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`${anchorObj.value === block._unique_key ? 'active' : ''}`}
                >
                    {
                        ((block.title && !block.titleHidden) || block.bodytext) &&
                        <section className="article__content">
                            {renderBodyText(block)}
                        </section>
                    }
                    <section className="article__content section--auto">
                        {renderMedia(block)}
                    </section>
                </div>
            );
        } else if (block.type === 'digitaltMuseum') {
            return (
                <div
                    id={id}
                    data-unique-key={block._unique_key}
                    key={`digitaltMuseum-${index}`}
                    onClick={() => anchorObj && anchorObj.handler(block._unique_key)}
                    className={`${anchorObj.value === block._unique_key ? 'active' : ''}`}
                >
                    {
                        ((block.title && !block.titleHidden) || block.bodytext) &&
                        <section className="article__content">
                            {renderBodyText(block)}
                        </section>
                    }
                    <section className="section--auto dm">
                        <Dm block={block} />
                    </section>
                </div>
            );
        }

        return null;
    };

    return (
        <div id="articleBody" className={classes.articleBody}>
            {blocks.map((b, index) => renderBlock(b, index))}
            {/* <div className="shareResources"><ShareResources /></div> */}
        </div>
    );
};
