import {
    SUBJECT_REQUESTED, SUBJECT_SUCCEEDED, SUBJECT_FAILED
} from '../actions/filter';

const initialState = {
    subject_data: [],
    subject_fetching: null,
    subject_error: false
};

const subject = (state = initialState, action) => {
    switch (action.type) {
        case SUBJECT_REQUESTED:
            return {
                ...state,
                subject_fetching: true,
                subject_error: false
            };
        case SUBJECT_SUCCEEDED:
            return {
                subject_data: action.data.resources,
                subject_fetching: false,
                subject_error: false
            };
        case SUBJECT_FAILED:
            return {
                ...state,
                subject_data: [],
                subject_fetching: false,
                subject_error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default subject;
