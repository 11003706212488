import React, { useState, useEffect } from 'react';
import { ArticleHeader } from './tpl/ArticleHeader';
import { ArticleBody } from './tpl/ArticleBody';
import { Footer } from './footer/Footer';
import { RelatedResources } from './RelatedResources';

let focusedBlock,
    scrolling = false;

export const Main = ({ data, focusId, onPreviewClick, anchorObj }) => {
    const { content } = data,
        { sections, page_type } = content,
        [focusedId, setFocusedId] = useState();

    useEffect(() => {
        if (focusedId !== focusId) {
            const id = focusId;

            try {
                const idArr = id.split('_'),
                    idx = parseInt(idArr[idArr.findIndex(x => x === 'blocks') + 1]),
                    block = document.getElementById(`@section_${idArr[3]}.block_${idx}`);

                if (!block) {
                    return;
                }

                scrolling = true;
                focusedBlock = block;
            } catch (error) {
                // console.log(error);
            }

            setFocusedId(focusId);
        } else if (focusedBlock) {
            focusedBlock.scrollIntoView({ behavior: scrolling ? 'smooth' : 'auto' });
            scrolling = false;
            focusedBlock = null;
        }
    }, [focusedId, focusId]);

    const handlePreviewClick = e => {
        const { target } = e;
        let id = target.id,
            p = target;

        while (id.substr(0, 8) !== '@section' && p.parentElement) {
            p = p.parentElement;
            id = p.id;
        }

        id = id.split('.');

        if (id.length > 1) {
            onPreviewClick && onPreviewClick(id[0].replace('@section_', ''), id[1].replace('block_', ''));
        }
    };

    return (
        <>
            <main className="content" onClick={handlePreviewClick}>
                <article className="article">
                    <ArticleHeader block={sections[0].blocks[0]} pageType={page_type} />
                    {(sections[1].blocks && !!sections[1].blocks.length) &&
                        <ArticleBody blocks={sections[1].blocks} anchorObj={anchorObj} />}
                </article>
            </main>
            <footer>
                {
                    !!sections[2] && !!sections[2].blocks && !!sections[2].blocks.length &&
                    <RelatedResources blocks={sections[2].blocks} />
                }
                <Footer />
            </footer>
        </>
    );
};
