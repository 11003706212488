import merge from 'deepmerge';
import delve from 'dlv';
import defaultConfig from './default';

const { REACT_APP_ENV, NODE_ENV } = process.env;

const ENV = REACT_APP_ENV || NODE_ENV;

const config = () => {
    try {
        return merge(defaultConfig, require(`./${ENV}.js`).default);
    } catch (e) {
        console.log(` CONFIG: Environment "${ENV}" not found.`);

        return defaultConfig;
    }
};


//export default config();
// https://github.com/developit/dlv
// config.get('my.nested.config', 'defaultValue')

export default {
    get: (value, def = '') => delve(config(), value, def),
};

