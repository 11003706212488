import React from 'react';
import { Trans } from 'react-i18next';

import headerSettings from './headerBlockSettings';
import imageWithFocusPoint from '../media/imageWithFocusPoint';

export default {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="owner.headerblock_title">Bilde og navn</Trans>,
    properties: {
        type: { enum: ['startpageConfig'] },
        _editor: {
            '$ref': '#/definitions/_editor'
        },
        settings: { ...headerSettings },
        name: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="owner.label_title">Navn</Trans>
        },
        resources: {
            type: 'object',
            title: 'media',
            properties: {
                backgroundImage: {
                    type: 'array',
                    maxItems: 1,
                    default: [],
                    items: {
                        type: 'object',
                        properties: {
                            ...imageWithFocusPoint.properties, local: {}, licenses: {}
                        }
                    }
                }
            }
        }
    }
};