import headerSection from '../header/headerSection';
import bodySection from '../body/bodySection';
import relatedSection from '../related/relatedArticlesSection';

export default {
    type: 'object',
    required: ['type'],
    properties: {
        type: {
            type: 'string',
            readOnly: true,
            title: null,
            enum: [
                'article__head',
                'article__body',
                'article__related'
            ],
            enumNames: [
                'Head',
                'Body',
                'Related'
            ],
            default: 'article__related'
        }
    },
    dependencies: {
        type: {
            oneOf: [
                headerSection,
                bodySection,
                relatedSection
            ]
        }
    }
}
