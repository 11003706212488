import React from 'react';
import { Trans } from 'react-i18next';

import uniqueKey from './uniqueKey';
import image from '../media/imageWithSettings';

export default {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="mediablock_title">Media</Trans>,
    properties: {
        type: { enum: ['article__media'] },
        title: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>
        },
        titleHidden: {
            type: 'boolean',
            title: <Trans ns="dm.article" i18nKey="label_hide-title">Skjul tittel</Trans>,
            enum: [true, false],
            default: false
        },
        bodytext: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_bodytext">Brødtekst</Trans>
        },
        settings: {
            type: 'object',
            properties: {
                layout: {
                    type: 'string',
                    enum: [
                        'grid',
                        'slideshow'
                    ],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="grid">Grid</Trans>,
                        <Trans ns="dm.article" i18nKey="slideshow">Slideshow</Trans>
                    ],
                    default: 'grid'
                },
                visibility: {
                    type: 'string',
                    enum: [
                        'visible',
                        'hidden'
                    ],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="visible">Synlig</Trans>,
                        <Trans ns="dm.article" i18nKey="hidden">Skjult</Trans>
                    ],
                    default: 'visible'
                }
            }
        },
        resources: {
            type: 'object',
            properties: {
                mediaList: {
                    type: 'array',
                    default: [],
                    items: image
                }
            }
        },
        _editor: { '$ref': '#/definitions/_editor' },
        _unique_key: uniqueKey
    }
};