//import { kitFetchExternal } from '@kulturit/header-state';
// import { kitFetch } from '@ekultur/header-state';
import config from 'common/config';

export const CATEGORY_REQUESTED = 'CATEGORY_REQUESTED';
export const CATEGORY_SUCCEEDED = 'CATEGORY_SUCCEEDED';
export const CATEGORY_FAILED = 'CATEGORY_FAILED';

export const LEVEL_REQUESTED = 'LEVEL_REQUESTED';
export const LEVEL_SUCCEEDED = 'LEVEL_SUCCEEDED';
export const LEVEL_FAILED = 'LEVEL_FAILED';

export const SUBJECT_REQUESTED = 'SUBJECT_REQUESTED';
export const SUBJECT_SUCCEEDED = 'SUBJECT_SUCCEEDED';
export const SUBJECT_FAILED = 'SUBJECT_FAILED';


export const requestFilter = type => dispatch => {
    dispatch({
        type: `${type.toUpperCase()}_REQUESTED`
    });

    fetch(`${config.get('publicApi')}minstemme/resources/${type}/`)
        .then(resp => {
            return resp.json();
        })
        .then(data => dispatch({
            type: `${type.toUpperCase()}_SUCCEEDED`,
            data: data
        })
        )
        .catch(error => dispatch({
            type: `${type.toUpperCase()}_FAILED`,
            data: error
        }));
};
