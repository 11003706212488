import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core';

export const OwnerWidget = props => {
    const { formContext, onChange, value } = props,
        { t } = useTranslation(['dm.article'], { useSuspense: false });

    const onToggle = e => {
        if (e.currentTarget.checked) {
            onChange(formContext.owner);
        } else {
            onChange('');
        }
    }

    return (
        <FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={typeof value === 'undefined' || value === '' ? false : value === formContext.owner}
                        onChange={onToggle}
                    /*id={id}
                    required={required}
                    disabled={disabled || readonly}
                    autoFocus={autofocus}
                    onBlur={_onBlur}
                    onFocus={_onFocus}*/
                    />
                }
                label={`${t('search-filter_owner', 'Egen samling')} (${formContext.owner})`}
            />
        </FormControl>
    )
};
