import React from 'react';
import { Trans } from 'react-i18next';

import {
    LAYOUTMODE_CHANGED, REPORT_EXPORT_ENABLE,
    CUSTOM_DATES_CHANGE, INBOX_FILTER_CHANGE
} from '../actions/layout';


const initialState = {
    layout: 'dashboard',
    view: '',
    filters: [
        {
            'title': <Trans ns="dm.timePeriod" i18nKey="today">I dag</Trans>,
            'value': 'today',
            'url': '?period=today',
            'start': 'today,yesterday',
            'end': 'today,yesterday',
            'timePeriod': 'today',
            'orderBy': 'nthHour'
        },
        {
            'title': <Trans ns="dm.timePeriod" i18nKey="yesterday">I går</Trans>,
            'value': 'yesterday',
            'url': '?period=yesterday',
            'start': 'yesterday,2daysAgo',
            'end': 'yesterday,2daysAgo',
            'timePeriod': 'yesterday',
            'orderBy': 'nthHour'
        },
        {
            'title': <Trans ns="dm.timePeriod" i18nKey="days" values={{ days: 7 }} defaults="Siste {{days}} dager" />,
            'value': '7days',
            'url': '?period=7days',
            'start': '7daysAgo,14daysAgo',
            'end': 'yesterday,8daysAgo',
            'timePeriod': 'week',
            'orderBy': 'nthDay'
        },
        {
            'title': <Trans ns="dm.timePeriod" i18nKey="quarter" values={{ q: 4 }} defaults="Q{{q}}" />,
            'url': '?period=q4',
            'value': 'q4',
            'timePeriod': 'q4',
            'orderBy': 'nthWeek'
        },
        {
            'title': <Trans ns="dm.timePeriod" i18nKey="quarter" values={{ q: 3 }} defaults="Q{{q}}" />,
            'url': '?period=q3',
            'value': 'q3',
            'timePeriod': 'q3',
            'orderBy': 'nthWeek'
        },
        {
            'title': <Trans ns="dm.timePeriod" i18nKey="quarter" values={{ q: 2 }} defaults="Q{{q}}" />,
            'url': '?period=q2',
            'value': 'q2',
            'timePeriod': 'q2',
            'orderBy': 'nthWeek'
        },
        {
            'title': <Trans ns="dm.timePeriod" i18nKey="quarter" values={{ q: 1 }} defaults="Q{{q}}" />,
            'url': '?period=q1',
            'value': 'q1',
            'timePeriod': 'q1',
            'orderBy': 'nthWeek'
        },
        {
            'title': <Trans ns="dm.timePeriod" i18nKey="year-to-date" defaults="Hittil i år" />,
            'url': '?period=year',
            'value': 'year',
            'timePeriod': 'year',
            'orderBy': 'nthMonth'
        },
        {
            'title': '2019',
            'url': '?period=2019',
            'value': '2019',
            'start': '2019-01-01,2018-01-01',
            'end': '2019-12-31,2018-12-31',
            'orderBy': 'nthMonth'
        },
        {
            'title': '2020',
            'url': '?period=2020',
            'value': '2020',
            'start': '2020-01-01,2019-01-01',
            'end': '2020-12-31,2019-12-31',
            'orderBy': 'nthMonth'
        },
        {
            'title': <Trans ns="dm.timePeriod" i18nKey="custom">Egendefinert</Trans>,
            'value': 'custom',
            'url': '?period=custom',
            'orderBy': 'nthHour'
        }
    ],
    inboxFilters: [
        {
            'title': <Trans ns="dm.mashup" i18nKey="status_all">Alle</Trans>,
            'value': 'all',
            'url': '?status=all'
        },
        {
            'title': <Trans ns="dm.mashup" i18nKey="status_open">Åpen</Trans>,
            'value': 'open',
            'url': '?status=open'
        },
        {
            'title': <Trans ns="dm.mashup" i18nKey="status_archived">Arkivert</Trans>,
            'value': 'archived',
            'url': '?status=archived'
        }
    ],
    menu: [
        {
            icon: 'home',
            title: 'DigitaltMuseum',
            url: '/'
        },
        {
            icon: 'home',
            title: 'Home',
            url: '/start'
        },
        {
            role: 'group',
            icon: 'inbox',
            title: <Trans ns="dm.menu" i18nKey="inbox">Innboks</Trans>,
            url: '/inbox',
            children: [
                {
                    title: <Trans ns="dm.menu" i18nKey="inbox_all">Alle</Trans>,
                    url: '/inbox'
                },
                {
                    title: <Trans ns="dm.menu" i18nKey="inbox_comments">Kommentarer</Trans>,
                    url: '/inbox/comment'
                },
                {
                    title: <Trans ns="dm.menu" i18nKey="inbox_inquiries">Tilbakemeldinger</Trans>,
                    url: '/inbox/inquiry'
                }
            ]
        },
        {
            role: 'group',
            icon: 'insert_chart',
            title: <Trans ns="dm.menu" i18nKey="statistics">Statistikk</Trans>,
            url: '/statistics',
            children: [
                {
                    title: <Trans ns="dm.menu" i18nKey="statistics_objectTypes">Objekttyper</Trans>,
                    url: '/statistics/objectTypes'
                },
                {
                    title: <Trans ns="dm.menu" i18nKey="statistics_downloads">Nedlastinger</Trans>,
                    url: '/statistics/downloads'
                }
            ]
        },
        {
            icon: 'view_stream',
            title: 'Artikler',
            url: '/article'
        }
    ],
    button: {
        children: [
            {
                title: <Trans ns="dm.menu" i18nKey="article">Artikkel</Trans>
            }
        ],
        title: <Trans ns="dm.menu" i18nKey="new_content">Nytt innhold</Trans>,
        disabled: true
    },
    reportExportEnabled: false,
    currentDetail: '7days',
    currentInboxFilter: 'all'
};

const layout = (state = initialState, action) => {
    switch (action.type) {
        case LAYOUTMODE_CHANGED:
            return {
                ...state,
                layout: action.layout,
                view: action.view,
                currentDetail: action.detail || state.currentDetail,
                parent: action.parent,
                reportExportEnabled: false
            };
        case REPORT_EXPORT_ENABLE:
            return {
                ...state,
                reportExportEnabled: action.reportExport
            };
        case INBOX_FILTER_CHANGE:
            return {
                ...state,
                currentInboxFilter: action.filter
            };
        case CUSTOM_DATES_CHANGE:
            return {
                ...state,
                filters: state.filters.map(f => {
                    if (f.value === 'custom') {
                        const start = setDateString(action.start),
                            end = setDateString(action.end),
                            diff = Math.floor((action.end - action.start) / (1000 * 60 * 60 * 24)),
                            orderBy = diff < 1 ? 'nthHour' : (diff < 14 ? 'nthDay' : (diff < 92 ? 'nthWeek' : 'nthMonth'));

                        return {
                            ...f,
                            start: start,
                            end: end,
                            orderBy: orderBy
                        }
                    } else {
                        return f;
                    }
                })
            }
        default:
            return {
                ...state
            }
    }
};

const setDateString = d => {
    const d1 = `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`;
    // d2 = `${d.getFullYear() - 1}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`;

    // return `${d1},${d2}`;
    return d1;
}

export default layout
