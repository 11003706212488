import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';

// import { requestFilter } from 'admin/src/redux/actions/filter';


const useStyles = makeStyles((theme) => ({
    inputRoot: {
        flex: '1 1 auto',
        marginRight: 16,
        background: 'white',
        borderRadius: 8,
        '&:last-child': {
            marginRight: 0
        },
        '& .MuiOutlinedInput-root': {
            height: 45,
            flexWrap: 'nowrap'
        },
    }
}));


export const SearchFilters = ({ onChange, selectedFilters, variant='outlined' }) => {
    const dispatch = useDispatch(),
        classes = useStyles(),
        { level_data } = useSelector(state => state.level),
        { subject_data } = useSelector(state => state.subject),
        { category_data } = useSelector(state => state.category);
        // [category, setCategory] = useState(null),
        // [level, setLevel] = useState(null),
        // [subject, setSubject] = useState(null);
        // { category, level, subject } = selectedValues;

    /* useEffect(() => {
        dispatch(requestFilter('level'));
        dispatch(requestFilter('subject'));
        dispatch(requestFilter('category'));
    }, [dispatch]); */

    /* useEffect(() => {
        const c = category_data.find(x => selectedValues.includes(x.id)),
            l = level_data.find(x => selectedValues.includes(x.id)),
            s = subject_data.find(x => selectedValues.includes(x.id));

        if (c && !category) {
            setCategory(c.id);
        }

        if (l && !level) {
            setLevel(l.id);
        }

        if (s && !subject) {
            setSubject(s.id);
        }
    }, [category_data, level_data, subject_data]); */

    /* useEffect(() => {
        let fltrs = [];

        if (category === null && level === null && subject === null) {
            return;
        }

        if (category) {
            fltrs.push(category);
        }

        if (level) {
            fltrs.push(level);
        }

        if (subject) {
            fltrs.push(subject);
        }

        onChange && onChange(fltrs);
    }, [category, level, subject]); */

    /* if (level_data.length === 0 || subject_data.length === 0 || category_data.length === 0) {
        return null;
    } */ /* else {
        if (category === null) {
            setCategory(category_data.find(x => selectedValues.includes(x.id)));
        }

        if (level === null) {
            setLevel(level_data.find(x => selectedValues.includes(x.id)));
        }

        if (subject === null) {
            setSubject(subject_data.find(x => selectedValues.includes(x.id)));
        }
    } */

    const commonOptions = {
        getOptionLabel: (option) => option.title,
        getOptionSelected: (option, value) => option.id === value.id,
        getOptionDisabled: (option) => !!selectedFilters.find(f => f.id === option.id)
    };

    const reformattedCategory = category_data.reduce((akku, row) => {
        const categoryRows = [];
        if (row.children.length > 0) {
            row.children.map((child) =>
                categoryRows.push({
                    title: child.title,
                    children: child.children,
                    id: child.id,
                    titleParent: row.title,
                    type: child.type
                }));
        } else if (row.children.length === 0) {
            categoryRows.push({ ...row, titleParent: 'Andre tema' });
        }
        akku = akku.concat(categoryRows);
        return akku;
    }, []);

    const PopperMinStm = function (props) {
        return <Popper {...props} placement="bottom-start" children={props.children} style={{ maxWidth: "fit-content" }} />;
    };

    return (
        <>
            <Autocomplete
                id="trinn"
                options={level_data}
                {...commonOptions}
                onChange={(event, item) => {
                    onChange(item);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Alle Trinn"
                        label={variant === 'filled' ? 'Trinn' : null}
                        variant={variant}
                    />
                )}
                className={classes.inputRoot}
                disableClearable
            />
            <Autocomplete
                id="fag"
                options={subject_data}
                {...commonOptions}
                PopperComponent={PopperMinStm}
                onChange={(event, item) => {
                    onChange(item);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Alle Fag"
                        label={variant === 'filled' ? 'Fag' : null}
                        variant={variant}
                    />
                )}
                className={classes.inputRoot}
                disableClearable
            />
            <Autocomplete
                id="tema"
                options={reformattedCategory}
                {...commonOptions}
                groupBy={(option) => option.titleParent}
                PopperComponent={PopperMinStm}
                onChange={(event, item) => {
                    onChange(item);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Alle Tema"
                        label={variant === 'filled' ? 'Tema' : null}
                        variant={variant}
                    />
                )}
                className={classes.inputRoot}
                disableClearable
            />
        </>
    );
};
