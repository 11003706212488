import { licenseMapper } from '../../helpers/licenseMapper';

export const imageMapper = item => {
    return {
        ...item,
        image_id: item.dms_id,
        licenses: (item.licenses && licenseMapper(item.licenses[0])) || [],
        local: {
            credit: item.owner_name,
            description: item.description || item.titledesc
        },
        settings: {
            size: 'medium'
        }
    };

    /*{
        created_by_id: 0,
        type: '',
        dms_id: '',
        mimetype: '',
        id: 0,
        url: '',
        dms_environment: '',
        filename: '',
        status: '',
        settings: {
            size: ''
        },
        media: {
            width: 0,
            height: 0,
            ratio: 0
        },
        local: {
            description: '',
            credit: ''
        },
        owner_identifier: '',
        created_at: '',
        updated_at: ''
    }*/
};
