const assignment = {
    type: 'object',
    properties: {
        headerImage: { type: 'string' },
        id: { type: 'integer' },
        dimu_unique_id: { type: 'string' },
        legacyId: { type: 'integer' },
        title: { type: 'string' },
        type: {
            type: 'string',
            enum: [ 'assignment' ],
            enumNames: [ 'Legg til relatert oppgave' ],
            default: 'assignment'
        }
    }
};

export default assignment;
