import {
    LEVEL_REQUESTED, LEVEL_SUCCEEDED, LEVEL_FAILED
} from '../actions/filter';

const initialState = {
    level_data: [],
    level_fetching: null,
    level_error: false
};

const level = (state = initialState, action) => {
    switch (action.type) {
        case LEVEL_REQUESTED:
            return {
                ...state,
                level_fetching: true,
                level_error: false
            };
        case LEVEL_SUCCEEDED:
            return {
                level_data: action.data.resources,
                level_fetching: false,
                level_error: false
            };
        case LEVEL_FAILED:
            return {
                ...state,
                level_data: [],
                level_fetching: false,
                level_error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default level;
