import React from 'react';
import { Trans } from 'react-i18next';

import textBlock from './textBlock';
import mediaBlock from './mediaBlock';
import dmBlock from './dmBlock';
import vissteDuBlock from "./vissteDuBlock";

export default {
    type: 'object',
    required: ['type'],
    properties: {
        type: {
            type: 'string',
            readOnly: true,
            enum: [
                'article__bodytext',
                'article__media',
                'digitaltMuseum',
                'article__visstedu'
            ],
            enumNames: [
                <Trans ns="dm.article" i18nKey="textblock_title">Tekst</Trans>,
                <Trans ns="dm.article" i18nKey="mediablock_title">Media</Trans>,
                <Trans ns="dm.article" i18nKey="dmblock_title">DigitaltMuseum</Trans>,
                'Visste Du'
            ],
            default: 'article__bodytext'
        }
    },
    dependencies: {
        type: {
            oneOf: [
                textBlock,
                mediaBlock,
                dmBlock,
                vissteDuBlock
            ]
        }
    }
};
