import React from 'react';
import { Trans } from 'react-i18next';

import uniqueKey from './uniqueKey';
import dmEntry from './dmEntry';

export default {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="dmblock_title">DigitaltMuseum</Trans>,
    properties: {
        type: { enum: ['digitaltMuseum'] },
        title: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>
        },
        titleHidden: {
            type: 'boolean',
            title: <Trans ns="dm.article" i18nKey="label_hide-title">Skjul tittel</Trans>,
            enum: [true, false],
            default: false
        },
        bodytext: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_bodytext">Brødtekst</Trans>
        },
        settings: {
            type: 'object',
            properties: {
                layout: {
                    type: 'string',
                    enum: [
                        'grid',
                        'grid-wide'
                    ],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="grid">Grid</Trans>,
                        <Trans ns="dm.article" i18nKey="grid_wide">Grid wide</Trans>
                    ],
                    default: 'grid'
                },
                visibility: {
                    type: 'string',
                    enum: [
                        'visible',
                        'hidden'
                    ],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="visible">Synlig</Trans>,
                        <Trans ns="dm.article" i18nKey="hidden">Skjult</Trans>
                    ],
                    default: 'visible'
                }
            }
        },
        resources: {
            type: 'object',
            properties: {
                entryList: {
                    type: 'array',
                    default: [],
                    items: dmEntry
                }
            }
        },
        labelHidden: {
            type: 'boolean',
            title: <Trans ns="dm.article" i18nKey="search-more_hidden">Skjul knapp</Trans>,
            enum: [true, false],
            default: false
        },
        maxListItems: {
            type: 'integer',
            title: <Trans ns="dm.article" i18nKey="search-more_max">Maks antall</Trans>
        },
        showMoreLabel: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>,
            default: 'Show all'
        },
        searchUrl: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="search-more_link">Url</Trans>
        },
        _editor: { '$ref': '#/definitions/_editor' },
        _unique_key: uniqueKey
    }
};