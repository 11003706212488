import React from 'react';
import { Trans } from 'react-i18next';

import headerSettings from './headerBlockSettings';
import imageWithFocusPoint from '../media/imageWithFocusPoint';

export default {
    type: 'object',
    title: <Trans ns="dm.article" i18nKey="headerblock_title">Tittel og ingress</Trans>,
    // required: [
    //     'type'
    // ],
    properties: {
        // type: {
        //     type: 'string',
        //     readOnly: true
        // },
        _editor: {
            '$ref': '#/definitions/_editor'
        },
        settings: { ...headerSettings },
        title: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>
        },
        author: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_author">Forfatter</Trans>
        },
        authorUrl: {
            type: 'string',
            title: 'Avsender url'
        },
        resources: {
            type: 'object',
            title: 'media',
            properties: {
                mediaList: {
                    type: 'array',
                    maxItems: 1,
                    default: [],
                    items: imageWithFocusPoint
                }
            }
        },
        leadtext: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="headerblock_ingress">Ingress</Trans>
        }
    }
}
