export default ({ spacing }) => {
    return {
        root: {
            width: '100%',
            '& > li': {
                backgroundClip: 'padding-box',
                '&:not(:last-child)': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                }
            }
        },
        listItem: {
            flexFlow: 'wrap',
            paddingLeft: spacing(1),
            paddingRight: 0
        },
        typeChip: {
            display: 'inline-flex',
            marginRight: spacing(.5),
            '& span': {
                marginTop: '-1px',
                overflow: 'visible'
            },
            '& + span': {
                marginLeft: spacing(.5)
            }
        },
        meta: {
            width: '100%',
            marginTop: spacing(.5),
            '& > span': {
                fontSize: '.875rem'
            },
            '& > span + span': {
                marginLeft: spacing(1)
            }
        },
        secondary: {
            fontSize: '.875rem',
            color: 'rgba(0, 0, 0, 0.54)',
            '& svg': {
                position: 'relative',
                top: spacing(.5),
                width: '.7em',
                height: '.7em',
                cursor: 'pointer'
            }
        },
        message: {
            width: '100%',
            marginTop: spacing(1),
            fontSize: '1rem',
            color: 'black'
        },
    }
};
