import React, { useEffect, useRef, useState } from 'react';
import Form from '@rjsf/material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import imageFileSchema from './schema/imageFileSchema';
import imageFileUiSchema from './schema/imageFileUiSchema';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
// import AddIcon from '@material-ui/icons/Add';
import {requestResources, updateResources, uploadMedia} from '../../redux/actions/media';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import FileSelector from './fileSelector';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import DeleteDialog from './deleteDialog';


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,

    },
    gridList: {
        width: "auto",
        height: "auto"
    },
    icon: {
        color: "rgba(255, 255, 255, 0.54)"
    },
    appBar: {
        position: "relative",
        backgroundColor: '#006C85'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    image: {
        cursor: 'pointer'
    },
    wrapper: {
        padding: 10,
        maxWidth: 1920,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    wrapperSearchAndFormGroup: {
        marginTop: 80,
        display: 'flex'
    },
    searchTextfield: {
        width: 400,
        marginBottom: 15,
        marginRight: 40
    },
    button: {
        marginTop: 10
    },
    searchInput: {
        borderRadius: 8,
        width: '100%',
        height: 48,
        [theme.breakpoints.down('sm')]: {
            width: 250,
        },
    },
    input: {
        display: 'none'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ImageGallery = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {user, owner} = useSelector(state => state.header);
    const {data, fetching, error} = useSelector(state => state.resources);

    const [images, setImages] = useState([]);
    const [selectedTile, setSelectedTile] = useState(null);
    const [ready, setReady] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isImgDataSaved, setIsImgDataSaved] = useState(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    let columns = matches ? 2 : 4;
    const pageRef = useRef();

    const [form, setForm] = useState({
        searchTerm: '',
        radioButtonValue: 'bilder'
    });

    const theme2 = createMuiTheme({
        palette: {
            primary: {
                main: '#006C85',
            },
            secondary: {
                main: '#E9EFF2',
            },
            action: {
                main: '#0083A8'
            }
        },
        typography: {
            fontFamily: [
                'Roboto',
                'sans-serif',
            ].join(','),
        },
    });

    useEffect(() => {
        dispatch(requestResources('', form))
    }, [dispatch, form,]);

    useEffect(() => {
        dispatch(requestResources('', ''))
    }, [dispatch, isImgDataSaved])

    useEffect(() => {
        if (fetching) {
            setReady(false);
        } else if (fetching === null || (!error && !fetching)) {
            if (data) {
                setReady(true);
                setImages(data.resources);
            }
        }
    }, [data, error, fetching]);


    const onFormChanged = form => {
        pageRef.current = form.formData;
    };

    const onSave = () => {
        dispatch(updateResources(user, pageRef.current, selectedTile));
        setIsEditDialogOpen(false);
        setIsImgDataSaved(true);
    };

    let timer = null;
    const onChangeInput = (e) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setForm({...form, searchTerm: e.target.value})
        }, 500);

    }

    const uploadHandler = file => {
        dispatch(uploadMedia(file, user.uniqueId));
    };

    const editDialogOpen = tile => {
        setIsEditDialogOpen(true)
        setIsImgDataSaved(false)
        setSelectedTile(tile);
    };

    const editDialogClose = () => {
        setIsEditDialogOpen(false)
    };

    const onDeleteOpen = () => {
        setIsDeleteDialogOpen(true);
    }

    const onDeleteClose = () => {
        setIsDeleteDialogOpen(false);
    }

    const handleChangeRadio = (event) => {
        setForm({...form, radioButtonValue: event.target.value});
    };

    return (
        <ThemeProvider theme={theme2}>
            <div className={classes.wrapper}>
                <div className={classes.wrapperSearchAndFormGroup}>
                    <div className={classes.searchTextfield}>
                        <TextField fullWidth id="standard-basic" placeholder="Søk etter media med fritekst og stikkord"
                                   variant="outlined"
                                   onChange={onChangeInput}
                                   InputProps={{
                                       className: classes.searchInput,
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               <SearchIcon color="primary"/>
                                           </InputAdornment>
                                       ),
                                   }}

                        />
                    </div>
                    <div>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={form.radioButtonValue === 'alle'}
                                        onChange={handleChangeRadio}
                                        name="all"
                                        color="primary"
                                        value="alle"
                                    />
                                }
                                label="Alle"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={form.radioButtonValue === 'bilder'}
                                        onChange={handleChangeRadio}
                                        name="images"
                                        color="primary"
                                        value="bilder"
                                    />
                                }
                                label="Bilder"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={form.radioButtonValue === 'audio'}
                                        onChange={handleChangeRadio}
                                        name="audio"
                                        color="primary"
                                        value="audio"
                                    />
                                }
                                label="Audio"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={form.radioButtonValue === 'video'}
                                        onChange={handleChangeRadio}
                                        name="video"
                                        color="primary"
                                        value="video"
                                    />
                                }
                                label="Video"
                            />
                        </FormGroup>
                    </div>
                </div>
                <div>
                    <FileSelector uploadHandler={uploadHandler}/>
                </div>

                <div className={classes.root}>
                    <GridList cellHeight={300} cols={columns}>
                        className={classes.gridList}
                        {images && images.map(tile => (
                            <GridListTile key={tile && tile.id} style={{minWidth: 400}}>
                                <img src={tile && tile.url && tile.url} alt={tile && tile.mimetype && tile.mimetype}
                                     className={classes.image}
                                     onClick={() => editDialogOpen(tile)}/>
                                <GridListTileBar
                                    title={tile && tile.filename}
                                    subtitle={
                                        <span>eier: {tile && tile.owner_identifier && tile.owner_identifier}</span>}
                                    actionIcon={
                                        <IconButton
                                            aria-label={`info about ${tile && tile.filename}`}
                                            className={classes.icon}
                                            value={tile && tile.id}
                                            onClick={() => onDeleteOpen()}
                                        >
                                            <DeleteForeverIcon/>
                                        </IconButton>
                                    }
                                />
                            </GridListTile>
                        ))}
                    </GridList>

                    <DeleteDialog
                        isDeleteDialogOpen={isDeleteDialogOpen}
                        onDeleteClose={onDeleteClose}
                        title={"filnavn"}
                    />

                    <Dialog
                        open={isEditDialogOpen}
                        onClose={editDialogClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <Typography variant="h6" className={classes.title}>
                                    {selectedTile && selectedTile.filename}
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={editDialogClose}
                                    aria-label="close"
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Box m={3}>
                            <Form
                                schema={imageFileSchema}
                                uiSchema={imageFileUiSchema}
                                formData={selectedTile && selectedTile.data}
                                onChange={onFormChanged}
                            >
                                <Button type="submit" variant="contained" color="primary" className={classes.button}
                                        onClick={onSave}>Lagre</Button>
                            </Form>
                        </Box>
                    </Dialog>

                </div>
            </div>
        </ThemeProvider>
    );
}
export default ImageGallery;
