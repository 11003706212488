import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Markdown from 'react-markdown/with-html';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { IconButton } from '@material-ui/core';

import { License } from './License';
import setImageStyle from 'admin/src/Article/imageFilters/filterStyle';
import { HeaderPortal } from '../header/Header';
import bgImage from '../header/headerBackground.jpeg';
import { ReactComponent as MinstemmeLogoSvart } from '../header/minstemme-logo_svart.svg';

// import { getImageDimensions } from '../../helpers/getImageDimensions';


const HEADER_IMAGE_MAX_WIDTH = 960,
    HEADER_IMAGE_HEIGHT = 500;

const useStyles = makeStyles(({spacing, palette, breakpoints}) => ({
    articleHead: {
        '& .backLink': {
            marginTop: 33,
            marginLeft: 43,
            display: 'flex',
            alignItems: 'center',
            '& a': {
                textDecoration: 'none',
                color: '#000000DE'
            },
            '& .arrowBackIosIcon': {
                width: 14,
                height: 14,
                color: '#000000DE'
            },
            [breakpoints.down('xs')]: {
                marginTop: 3,
                marginLeft: 3,
            }
        },
        '.anchorMode &': {
            cursor: 'not-allowed',
            opacity: .5
        },
        '&:not(.owner)': {
            /*paddingTop: '1.5em'*/
        },
        '& .wrapperTitleAuthor': {
            margin: 0,
            '& .article__title': {
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: 720,
                textAlign: 'center',
                /* lineHeight: '1.25em',*/
                fontWeight: 'bold',
                fontSize: '48px',
                // marginTop: 67,
                marginBottom: 40,
                '& h1': {
                    margin: 0
                },
                [breakpoints.down('xs')]: {
                    fontSize: 18,
                    marginLeft: 7,
                    marginRight: 7,
                    textAlign: 'center',
                    marginTop: 10,
                    marginBottom: 10,
                },
            },
            '& .article__byline': {

                marginBottom: 27,
                fontSize: 14,
                textAlign: 'center',
                '& p': {
                    margin: 0
                },
                '& a': {
                    textDecoration: 'none',
                    color: '#000000'
                },
            }
        },

        '& .article__cover': {
            position: 'relative',
            /*width: '100%',*/
            height: '144px',
            overflow: 'hidden',
            /*backgroundColor: '#423f3c',*/
            color: '#000',
            [breakpoints.up('sm')]: {
                height: `${HEADER_IMAGE_HEIGHT}px`
            },
            '&.skin-light': {
                color: '#000'
            },

            '& .mediaWrapper': {
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                '& figure.media--cover': {
                    height: '100%',
                    maxWidth: `${HEADER_IMAGE_MAX_WIDTH}px`,
                    margin: '0 auto',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }
            },
            '& .article__content': {

                position: 'relative',
                height: '35%',
                '& .titleWrapper': {
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    left: 0,
                    bottom: 'auto',
                    margin: '0 auto',
                    transform: 'translateY(-50%)',
                    zIndex: 1
                },
                '& .media__credit': {
                    position: 'absolute',
                    bottom: 0,
                    padding: '.75em',
                    opacity: .5,
                    zIndex: 1
                },
            },
        },
        '&.owner .article__cover': {
            height: '760px',
            '& .mediaWrapper figure.media--cover': {
                maxWidth: '1680px'
            }
        },
        '& .article__content': {
            '& .article__leadtext': {
                paddingTop: '1.5em',
            }
        },
        '& .owner__intro': {
            width: '80%'
        },
        '& .media_credit': {
            maxWidth: `${HEADER_IMAGE_MAX_WIDTH}px`,
            margin: '0 auto',
            fontSize: 16,
            paddingTop: '.3em',
            '& .media__caption': {
                fontWeight: 'normal'
            },
            [breakpoints.down('xs')]: {
                fontSize: 14,
                paddingLeft: 7
            }
        }
    }
}));


export const ArticleHeader = ({ block, pageType, children }) => {
    const classes = useStyles(),
        [imgDimensions, setImgDimensions] = useState(),
        [imgTopPos, setImgTopPos] = useState(0),
        { resources } = block,
        { mediaList, backgroundImage } = resources,
        bgImg = mediaList || backgroundImage,
        [fp, setFp] = useState(bgImg && bgImg.length && bgImg[0].media.focuspoint);

    useEffect(() => {
        if (bgImg && bgImg.length && imgDimensions) {
            if (bgImg[0].media && bgImg[0].media.focuspoint) {
                const containerCenter = Math.floor(HEADER_IMAGE_HEIGHT / 2),
                    scale = HEADER_IMAGE_MAX_WIDTH / imgDimensions.width,
                    height = scale * imgDimensions.height,
                    focusFactor = (parseFloat(bgImg[0].media.focuspoint.y) + 1) / 2, //Focus point of resize image in px
                    focus = height - Math.floor(focusFactor * height),
                    remainder = height - focus, //Reduce offset if necessary so image remains filled
                    containerRemainder = HEADER_IMAGE_HEIGHT - containerCenter;

                let focusOffset = focus - containerCenter; //Calculate difference between focus point and center

                if (remainder < containerRemainder) focusOffset -= containerRemainder - remainder;
                if (focusOffset < 0) focusOffset = 0;

                setImgTopPos(focusOffset * -100 / HEADER_IMAGE_HEIGHT + '%');
            }
        }
    }, [imgDimensions, imgTopPos, bgImg, fp]);

    const onLoadedData = e => {
        setImgDimensions({ width: e.target.width, height: e.target.height });
    };

    if (bgImg && bgImg.length && fp && bgImg[0].media.focuspoint && bgImg[0].media.focuspoint.y !== fp.y) {
        setFp(bgImg[0].media.focuspoint);
    }

    return (
        <header id="@section_0.block_0" className={`${classes.articleHead} ${pageType}`}>
            <HeaderPortal
                backgroundImage={`linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)),url(${bgImage})`}
                height="96px"
                langIcon={{ fill: 'black' }}
                infoIcon={{ fill: 'black' }}
                color="black"
            >
                <MinstemmeLogoSvart />
            </HeaderPortal>
            <div className="backLink">
                <IconButton href="/"><ArrowBackIosIcon className="arrowBackIosIcon" /></IconButton>
                <Link to="/">Forside</Link>
            </div>
            <div className="wrapperTitleAuthor">
                <div className="article__title">
                    <Markdown source={block.title} escapeHtml={false} />
                </div>
                {block.author &&
                    <div className="article__byline">
                        <a href={block.authorUrl}>Av {block.author}</a>
                        {/*<p>Av {block.author}</p>*/}
                    </div>
                }
            </div>

            {
                !!bgImg.length && (
                    <>
                        <section className={`article__cover ${block.settings.color}`}>
                            <div className="mediaWrapper">
                                <figure className="article__media  media--cover media--image focuspoint"
                                    id="article__cover-media"
                                    style={{
                                        ...setImageStyle(bgImg[0].media),
                                        backgroundImage: `url('${bgImg[0].url}')`
                                    }}
                                >
                                    <img src={bgImg[0].url} alt={bgImg[0].local && bgImg[0].local.description}
                                        style={{
                                            position: 'absolute',
                                            top: `${imgTopPos}`,
                                            left: '0px',
                                            height: 'auto',
                                            width: '100%'
                                        }}
                                        onLoad={onLoadedData}
                                    />
                                </figure>
                            </div>
                        </section>
                    </>
                )
            }

            {mediaList && mediaList[0] && (
                <div className="media_credit">
                    {mediaList[0].local && mediaList[0].local.description !== '' &&
                        <span className="media__caption">{mediaList[0].local.description}</span>}
                    {mediaList[0].local && mediaList[0].local.credit &&
                        <span className="media__credit">{mediaList[0].local.credit}</span>}
                    {mediaList[0].licenses && mediaList[0].licenses[0] && <License code={mediaList[0].licenses[0]} />}
                </div>
            )}
            {pageType === 'article' && (
                <section className="article__content">
                    <div className="article__leadtext">
                        <strong><Markdown source={block.leadtext} escapeHtml={false} /></strong>
                    </div>
                </section>
            )}
        </header>
    );
};
