const commaSeparateIds = form => {
    // return form.filters.join(',');
    return form.filters.map(f => f.id);
};

const prefix = params => {
    return params === '' ? '?' : '&';
}

export const setParams = (form) => {
    if (!form) {
        return '';
    }

    let params = '';
    
    if (form.radioButtonValue) {
        params = `${params}${prefix(params)}page_type=${form.radioButtonValue.toLowerCase()}`;
    }

    if (form.checkedArticle) {
        params = `${params}${prefix(params)}page_type=article`;
    }

    if (form.checkedAssignment) {
        params = `${params}${prefix(params)}page_type=assignment`;
    }

    if (form.checkedContribution) {
        params = `${params}${prefix(params)}page_type=contribution`;
    }

    if (form.filters.length > 0) {
        params = `${params}${prefix(params)}filter=${commaSeparateIds(form)}`;
    }

    if (form.searchTerm) {
        params = `${params}${prefix(params)}q=${form.searchTerm}`;
    }

    if (form.limit) {
        params = `${params}${prefix(params)}limit=${form.limit}`;
    }

    if (form.offset) {
        params = `${params}${prefix(params)}offset=${form.offset}`;
    }

    return params;
};
