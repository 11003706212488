import React from 'react';
import { Switch } from 'react-router-dom';
import { AuthsRoutes, PrivateRoute } from '@ekultur/header-state';
import { UserAccess } from './App/UserAccess';

// import {Home} from './Home';
// import {Private} from './Private';
import { ArticlesList } from './Article/ArticlesList';
import { ArticleEditor } from './Article/ArticleEditor';
// import {ArticleView} from 'portal/src/articleView';
import ImageGallery from './Article/imageGallery/imageGallery';


export const AppRoutes = () => {
    return (
        <UserAccess>
            <Switch>
                {/*<Route exact path='/' component={Home}/>*/}
                <PrivateRoute exact path={`${process.env.PUBLIC_URL}/`} component={ArticlesList}/>
                <PrivateRoute exact path={`${process.env.PUBLIC_URL}/gallery`} component={ImageGallery}/>
                <PrivateRoute exact path={`${process.env.PUBLIC_URL}/article/edit/:articleId`} component={ArticleEditor}/>
                {/* <PrivateRoute exact path='/private'>
                    <Private/>
                </PrivateRoute> */}
                <AuthsRoutes/>
            </Switch>
        </UserAccess>
    )
};
