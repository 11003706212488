import React from 'react';
import { Trans } from 'react-i18next';

const image = {
    type: 'object',
    properties: {
        status: { type: 'string' },
        type: { type: 'string' },
        created_by_id: { type: 'integer' },
        image_id: { type: 'string' },
        created_at: { type: 'string' },
        mimetype: { type: 'string' },
        owner_identifier: { type: 'string' },
        /* title: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="image_text">Bildetekst</Trans>
        },
        producer: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="image_credit">Fotograf</Trans>
        }, */
        id: { type: 'integer' },
        filename: { type: 'string' },
        dms_environment: { type: 'string' },
        url: { type: 'string' },
        source: { type: 'string' },
        local: {
            type: 'object',
            title: null,
            properties: {
                credit: {
                    type: 'string',
                    title: <Trans ns="dm.article" i18nKey="image_credit">Fotograf</Trans>
                },
                description: {
                    type: 'string',
                    title: <Trans ns="dm.article" i18nKey="image_text">Bildetekst</Trans>
                }
            }
        },
        licenses: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
            title: null,
            default: [],
            items: {
                type: 'string',
                enum: [
                    'CC pdm',
                    'CC CC0',
                    'CC by',
                    'CC by-sa',
                    'CC by-nc',
                    'CC by-nc-sa',
                    'CC by-nd',
                    'CC by-nc-nd',
                    'copyright'
                ],
                enumNames: [
                    <Trans ns="dm.article" i18nKey="license_PDM">Falt i det fri (PDM)</Trans>,
                    <Trans ns="dm.article" i18nKey="license_ZERO">Ingen rettigheter forbeholdt (CC0)</Trans>,
                    <Trans ns="dm.article" i18nKey="license_BY">Kun navngivelse (BY)</Trans>,
                    <Trans ns="dm.article" i18nKey="license_BY-SA">Navngivelse, del på samme vilkår (CC BY-SA)</Trans>,
                    <Trans ns="dm.article" i18nKey="license_BY-NC">Navngivelse, ikke kommersiell (CC BY-NC)</Trans>,
                    <Trans ns="dm.article" i18nKey="license_BY-NC-SA">Navngivelse, ikke kommersiell, ingen bearbeidelse (CC BY-NC-SA)</Trans>,
                    <Trans ns="dm.article" i18nKey="license_BY-ND">Navngivelse + Ingen bearbeidelse (BY-ND)</Trans>,
                    <Trans ns="dm.article" i18nKey="license_BY-NC-ND">Navngivelse, ikke kommersiell, ingen bearbeidelser (CC BY-NC-ND)</Trans>,
                    <Trans ns="dm.article" i18nKey="license_COPYRIGHT">Rettighetsbelagt (Opphavsrett)</Trans>,
                ],
                default: ''
            }
        },
        media: {
            type: 'object',
            properties: {
                width: { type: 'integer' },
                height: { type: 'integer' },
                ratio: { type: 'number' },
                contrast: {
                    type: 'integer',
                    default: 100
                },
                grayscale: {
                    type: 'integer',
                    default: 0
                },
                hue: {
                    type: 'integer',
                    default: 0
                },
                brightness: {
                    type: 'integer',
                    default: 100
                },
                saturate: {
                    type: 'integer',
                    default: 100
                },
                sepia: {
                    type: 'integer',
                    default: 0
                },
                invert: {
                    type: 'integer',
                    default: 0
                }
            }
        },
        imgattr: {
            type: 'object',
            properties: {
                height: { type: 'integer' },
                offetLeft: { type: 'integer' },
                offsetTop: { type: 'integer' },
                width: { type: 'integer' }
            }
        }
    }
};

export default image;
