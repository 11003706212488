import React from 'react';
import { FieldTemplate as DefaultFT } from '@rjsf/material-ui';
import Box from '@material-ui/core/Box';

function CustomArrayFieldTemplate(arrayFieldProps) {
    return (
        <React.Fragment>
            {arrayFieldProps.items.map((element, index) =>
                (
                    <Box key={index}>
                        {element.children}
                    </Box>
                )
            )}
        </React.Fragment>
    );
};

export default {
    title: {
        'ui:FieldTemplate': DefaultFT,
    },
    imageDescription: {
        'ui:FieldTemplate': DefaultFT,
    },
    alternativeText: {
        'ui:FieldTemplate': DefaultFT,
    },
    licenses: {
        'ui:ArrayFieldTemplate': CustomArrayFieldTemplate
    },
    tags: {
        'ui:ArrayFieldTemplate': CustomArrayFieldTemplate
    }
};
