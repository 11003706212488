import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Info from '@material-ui/icons/Info';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';

const useStyles = makeStyles((theme) => ({
    bgImage: {
        /*backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bgImage}) no-repeat center`,*/
        backgroundImage: props => props.backgroundImage,
        /*width: '1440px',*/
        width: '100%',
        height: props => props.height,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        /*objectFit: "cover",
        objectPosition: "center center"*/
      /*  overflow: 'hidden'*/
    },
    svgLogo: {
        width: '198px',
        marginTop: '24px',
        marginLeft: '24px',
        [theme.breakpoints.down('sm')]: {
            width: '124px'
        },
    },
    spacingLogoIcons: {
        display: "flex",
        justifyContent: "space-between"
    },
    iconsSpacing: {
        display: 'flex',
        alignSelf: 'flex-start',
    },
    iconLabelSpacingLanguage: {
        display: "flex",
        alignItems: "center",
        marginTop: '26px',
        '& p': {
            textDecoration: 'none',
            color: props => props.color,
            fontSize: '18px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14PX'
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '15PX'
        },
    },
    iconLabelSpacingMer: {
        display: "flex",
        alignItems: "center",
        marginTop: '26px',
        marginRight: '24px',
        marginLeft: '26.5px',
        '& a': {
            textDecoration: 'none',
            color: props => props.color,
            fontSize: '18px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14PX'
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '15PX'
        },
    },
    langIcon: props => ({ ...props.langIcon, marginRight: '7px'}),
    infoIcon: props => ({ ...props.infoIcon, marginRight: '7px'}),

}));

export const HeaderPortal = ({children, ...props}) => {
    const classes = useStyles(props);

    return (
        <div className={`${classes.bgImage} ${classes.spacingLogoIcons}`}>
            <a href="/" className={classes.svgLogo}>
                {children}
            </a>
            <div className={classes.iconsSpacing}>
                <div className={classes.iconLabelSpacingLanguage}>
                    <LanguageOutlinedIcon className={classes.langIcon}/>
                    <p>Bokmål</p>
                </div>
                <div className={classes.iconLabelSpacingMer}>
                    <Info className={classes.infoIcon}/>
                    <a href="/">Mer</a>
                </div>
            </div>
        </div>
    );
}

