import React from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';


export const SearchFilterDropdownWidget = ({ options, value, label, id, onChange }) => {
    return (
        <FormControl style={{width: '100%'}}>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select labelId={`${id}-select`} value={value || ''} onChange={e => onChange(e.target.value)}>
                {options.enumOptions.map(t => <MenuItem key={t.value} value={t.value}>{t.label}</MenuItem>)}
            </Select>
        </FormControl>
    );
};