import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 2880,
        // minHeight: 500,
        backgroundColor: '#E9EFF2',
        marginLeft: 'auto',
        marginRight: 'auto',
       /* marginTop: 80,*/
        '& .gridContainer': {
            justifyContent: 'center',
            '& .gridItem': {
                marginTop: theme.spacing(5),
                marginBottom: theme.spacing(5),
                [theme.breakpoints.down('xs')]: {
                    marginTop: 0,
                },
                '& div': {
                    marginTop: 19
                }
            }
        },
        '& h2': {
            fontSize: 24,
            [theme.breakpoints.down('xs')]: {
                fontSize: 16
            }
        }
    }
}));

export const RelatedResources = ({ blocks }) => {
    const classes = useStyles(),
        artBlock = blocks.find(b => b.type === 'related__resources'),
        asmBlock = blocks.find(b => b.type === 'related__assignments'),
        conBlock = blocks.find(b => b.type === 'related__contributions');

    if (!artBlock && !asmBlock && !conBlock) {
        return null;
    }

    const renderRelatedLink = rel => {
        return (
            <div key={rel.title}>
                <a href={`/${rel.dimu_unique_id}`}>{rel.title}</a>
            </div>
        );
    };

    return (
        <Container className={classes.container}>
            <Grid container spacing={3} className="gridContainer">
                {artBlock && artBlock.resources && artBlock.resources.relatedList
                    ? (
                        <Grid item xs={12} sm={6} md={4} lg={3} className="gridItem">
                            <h2>Relaterte Ressurser</h2>
                            {artBlock.resources.relatedList.map(renderRelatedLink)}
                        </Grid>
                    ) : null
                }
                {asmBlock && asmBlock.resources && asmBlock.resources.relatedList
                    ? (
                        <Grid item xs={12} sm={6} md={4} lg={3} className="gridItem">
                            <h2>Relaterte Oppgaver</h2>
                            {asmBlock.resources.relatedList.map(renderRelatedLink)}
                        </Grid>
                    ) : null
                }
                {conBlock && conBlock.resources && conBlock.resources.relatedList
                    ? (
                        <Grid item xs={12} sm={6} md={4} lg={3} className="gridItem">
                            <h2>Relaterte Innsendte Bidrag</h2>
                            {conBlock.resources.relatedList.map(renderRelatedLink)}
                        </Grid>
                    ) : null
                }
            </Grid>
        </Container>
    );
};
