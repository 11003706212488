import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './root-reducer';
//import {persistStore} from 'redux-persist'

const middleware = [thunk];


export default function configureStore(preloadedState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    /*let store = createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(...middleware)));
    let persistor = persistStore(store);
    const configuredObj = {store, persistor};
    console.log("store and persistor :", configuredObj);
    return configuredObj;*/
    return createStore(
        rootReducer,
        preloadedState,
        composeEnhancers(
            applyMiddleware(...middleware)
        )
    );
}

//const store = createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(...middleware));
// const persistor = persistStore(store);

//export default {store, persistor};
