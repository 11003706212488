export default {
    definitions: {
        _editor: {
            type: 'object',
            title: null,
            properties: {
                _collapse: {
                    type: 'boolean',
                    enum: [true, false],
                    default: false
                }
            }
        }
    },
    type: 'object',
    properties: {
        content: {
            type: 'object',
            title: null,
            properties: {}
        }
    }
}