import React from 'react';
import { Trans } from 'react-i18next';

import settings from './logoDescSettings';
// import imageWithFocusPoint from '../media/imageWithFocusPoint';

// let img = { ...imageWithFocusPoint, local: null };
// delete img.properties.local;

export default {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="owner.headerblock.logoDesc_title">Logo og beskrivelse</Trans>,
    properties: {
        type: { enum: ['startpageLogoDescription'] },
        _editor: {
            '$ref': '#/definitions/_editor'
        },
        settings: { ...settings },
        presentation: { type: 'string' },
        homepage: { type: 'string' },
        resources: {
            type: 'object',
            title: 'media',
            properties: {
                logo: {
                    type: 'array',
                    maxItems: 1,
                    default: [],
                    items: {
                        type: 'object',
                        properties: {
                            status: { type: 'string' },
                            type: { type: 'string' },
                            created_by_id: { type: 'integer' },
                            image_id: { type: 'string' },
                            created_at: { type: 'string' },
                            mimetype: { type: 'string' },
                            owner_identifier: { type: 'string' },
                            id: { type: 'integer' },
                            filename: { type: 'string' },
                            dms_environment: { type: 'string' },
                            url: { type: 'string' },
                            source: { type: 'string' },
                            media: {
                                type: 'object',
                                properties: {
                                    width: { type: 'integer' },
                                    height: { type: 'integer' },
                                    ratio: { type: 'number' }
                                }
                            }
                        }
                    }
                },
                linkList: {
                    type: 'array',
                    items: {
                        type: 'object',
                        required: ['type'],
                        properties: {
                            type: {
                                type: 'string',
                                readOnly: true,
                                enum: ['link', 'article__bodytext'],
                                default: 'link'
                            }
                            // subtype: { type: 'string' }
                        },
                        dependencies: {
                            type: {
                                oneOf: [
                                    {
                                        type: 'object',
                                        required: ['type'],
                                        properties: {
                                            type: { enum: ['link'] },
                                            title: {
                                                type: 'string',
                                                title: <Trans ns="dm.article" i18nKey="owner.headerblock.logoDesc.link_title">Tittel</Trans>
                                            },
                                            url: { type: 'string' }
                                        }
                                    }, {
                                        type: 'object',
                                        required: ['type'],
                                        properties: {
                                            type: { enum: ['article__bodytext'] },
                                            title: {
                                                type: 'string',
                                                title: <Trans ns="dm.article" i18nKey="owner.headerblock.logoDesc.link_title">Tittel</Trans>
                                            },
                                            _unique_key: { type: 'string' },
                                            _editor: {
                                                type: 'object'/* ,
                                                properties: {
                                                    firstItem: { type: 'boolean' }
                                                } */
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }
        }
    }
};
