import dmObject from './dmObject';
import dmSearch from './dmSearch';

export default {
    type: 'object',
    required: ['type'],
    properties: {
        type: {
            type: 'string',
            readOnly: true,
            title: null,
            enum: [
                'DM',
                'search'
            ],
            enumNames: [
                'DM',
                'Search'
            ],
            default: 'search'
        }
    },
    dependencies: {
        type: {
            oneOf: [
                dmObject,
                dmSearch
            ]
        }
    }
}