import React from 'react';
import { Trans } from 'react-i18next';
// import sectionContent from './sectionContent';
// import sectionMedia from './sectionMedia';
// import sectionObjects from './sectionObjects';
// import sectionMap from './sectionMap';
import uniqueKey from './uniqueKey';
import image from '../media/imageWithSettings';

export default {
    type: 'object',
    required: ['type'],
    title: <Trans ns="dm.article" i18nKey="textblock_title">Tekst</Trans>,
    properties: {
        type: { enum: ['article__bodytext'] },
        settings: {
            type: 'object',
            properties: {
                layout: {
                    type: 'string',
                    enum: [
                        'auto',
                        'img-left',
                        'img-right'
                    ],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="align-image_auto">Auto</Trans>,
                        <Trans ns="dm.article" i18nKey="align-image_left">Bilde til venstre</Trans>,
                        <Trans ns="dm.article" i18nKey="align-image_right">Bilde til høyre</Trans>
                    ],
                    default: 'auto'
                },
                visibility: {
                    type: 'string',
                    enum: [
                        'visible',
                        'hidden'
                    ],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="visible">Synlig</Trans>,
                        <Trans ns="dm.article" i18nKey="hidden">Skjult</Trans>
                    ],
                    default: 'visible'
                }
            }
        },
        title: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_title">Tittel</Trans>
        },
        titleHidden: {
            type: 'boolean',
            title: <Trans ns="dm.article" i18nKey="label_hide-title">Skjul tittel</Trans>,
            enum: [true, false],
            default: false
        },
        bodytext: {
            type: 'string',
            title: <Trans ns="dm.article" i18nKey="label_bodytext">Brødtekst</Trans>
        },
        resources: {
            type: 'object',
            properties: {
                mediaList: {
                    type: 'array',
                    default: [],
                    maxItems: 1,
                    items: image
                }
            }
        },
        _editor: { '$ref': '#/definitions/_editor' },
        _unique_key: uniqueKey
    },
    // dependencies: {
    //     sectionType: {
    //         oneOf: [
    //             sectionContent,
    //             sectionMedia,
    //             sectionObjects,
    //             sectionMap
    //         ]
    //     }
    // }
}