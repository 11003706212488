import headerSection from '../header/ownerHeaderSection';
import bodySection from '../body/bodySection';
//import footerSection from '../footer/footerSection';

export default {
    type: 'object',
    required: ['type'],
    properties: {
        type: {
            type: 'string',
            readOnly: true,
            title: null,
            enum: [
                'article__head',
                'article__body',
                'footer_section'
            ],
            enumNames: [
                'Head',
                'Body',
                'Footer'
            ],
            default: 'article__body'
        }
    },
    dependencies: {
        type: {
            oneOf: [
                headerSection,
                bodySection,
               // footerSection
            ]
        }
    }
}
