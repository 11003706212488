import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { Authenticator } from '@ekultur/header-state';
import { AppRoutes } from './AppRoutes';
import { Layout } from './Layout';
// import { MinstemmmePortal } from 'portal/src/Home';
// import { ArticleView } from 'portal/src/ArticleView';
import ScrollToTop from 'portal/src/scrollToTop';
// import ImageGallery from './Article/imageGallery/imageGallery';

export const App = () => {
    return (

        <Router>
            <ScrollToTop/>
            {/* <Route exact path='/portal' component={MinstemmmePortal}/>
            <Route exact path='/view/:pageId' component={ArticleView}/>
            <Route exact path='/gallery' component={ImageGallery}/> */}
            <Authenticator appId={window._env_.REACT_APP_ID} app={{version: window._env_.REACT_APP_VERSION}}>
                <Layout>
                    <AppRoutes/>
                </Layout>
            </Authenticator>
        </Router>
    );
};
