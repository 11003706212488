const config = {
    api: '/dm-admin-api/v1/',
    articleApi: '/dm-admin-api/v1/',
    museumApi: '/museum-api/museums/',
    placeApi: 'https://place-api.dimu.org/api/search',
    dmUrl: `${process.env.REACT_APP_DM4_URL}`,
    publicApi: `${process.env.REACT_APP_API_GATEWAY}/dm-admin-api/public/v1/`
};

export default config;
