import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTheme } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import {
    FormControl,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    InputLabel,
    // TextField,
    Input,
    Button,
    Chip
} from '@material-ui/core';
// import Autocomplete from "@material-ui/lab/Autocomplete";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import appConfig from 'common/config';
import {
    requestResources, requestDmSearch, uploadMedia, uploadVideo,
    uploadVideoStatus, publishVideo, videoDialogDismissed
 } from '../../redux/actions/media';

import { Accordion } from '../schema/widgets/Accordion';
import { Error } from '../../common/Error';
import { ProgressIndicator } from '../../common/ProgressIndicator';
import useMashupStyles from '../../Mashup/MashupStyles';

import { svgToSrc } from '../helpers/svgToSrc';
import { imageMapper } from './tpl/image';
import { dmObjMapper } from './tpl/dmObj';
import artifactTypes from '../schema/searchFilters/artifactTypeEnum';
import sort from '../schema/searchFilters/sortEnum';
import order from '../schema/searchFilters/sortOrderEnum';

import { ImageSelector } from './ImageSelector';
import { UploadDialog } from './UploadDialog';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    header: {
        position: 'fixed',
        display: 'flex',
        height: spacing(8),
        width: '49.9%',
        backgroundColor: '#f2f2f2',
        boxShadow: '0 1.5px 4px rgba(0, 0, 0, 0.12)',
        zIndex: 1,
        '& input': {
            width: '100%',
            height: '100%',
            padding: 0
        },
        '& input, & button': {
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent'
        },
        '& button': {
            cursor: 'pointer'
        }
    },
    searchIcon: {
        width: spacing(8),
        height: spacing(8),
        padding: spacing(2.5),
        boxSizing: 'border-box'
    },
    resourceSection: {
        paddingTop: spacing(9),
        marginBottom: spacing(1),
        maxHeight: '45%',
        overflowY: 'hidden',
        // '&.expanded': {
        //     overflowY: 'scroll'
        // }
    },
    input: {
        display: 'none'
    },
    searchSection: {
        paddingBottom: spacing(1)
    },
    uploadedWrapper: {
        display: 'flex',
        flexFlow: 'wrap',
        paddingTop: spacing(2),
        '& .image--preview': {
            marginRight: '.5em',
            marginBottom: '.5em',
            '& .svg': {
                width: '167px',
                height: '167px'
            },
            '&.selected': {
                opacity: .5
            },
            '& .document': {
                display: 'flex',
                alignItems: 'center',
                '& img': {
                    width: '48px'
                },
                '& span': {
                    padding: '0 0 0 .25em'
                }
            },
            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    filters: {
        display: 'flex',
        flexFlow: 'wrap',
        marginBottom: '1em',
        '& > p': {
            width: '100%',
            marginBottom: 0
        },
        '& > div': {
            width: '100px',
            flex: '1 1',
            marginRight: spacing(1),
            '&:first-of-type': {
                flex: '1 1 auto',
                marginTop: '.7rem'
            }
        }
    },
    spaceAutocomplete: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    moreBtn: {
        marginTop: spacing(1),
        marginBottom: spacing(1)
    },
    mediaItem: {
        display: 'flex',
        flex: '1 0 0',
        position: 'relative',
        marginTop: spacing(1),
        padding: spacing(1),
        backgroundColor: '#f5f5f5',
        border: '1px solid #ddd',
        cursor: 'pointer',
        '&.selected': {
            '& .image--preview, & .fieldWrapper': {
                opacity: .5
            }
        },
        '& .fieldWrapper': {
            display: 'flex',
            flexFlow: 'wrap',
            width: '100%',
            '& label + .MuiInput-formControl': {
                marginTop: spacing(1.5)
            }
        },
        '& .image--preview': {
            position: 'relative',
            minWidth: spacing(9),
            minHeight: spacing(9),
            marginRight: '.5em',
            background: '#423f3c',
            '& img': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'auto',
                height: 'auto',
                margin: 'auto'
            },
            '& .imgCount': {
                position: 'absolute',
                background: 'rgba(255, 255, 255, .5)',
                padding: '0 4px',
                fontSize: '13px'
            }
        },
        '& .popper': {
            zIndex: 1,
            '& .paper': {
                padding: spacing(1)
            },
            '& img': {
                padding: spacing(.25),
                '&.selected': {
                    opacity: .5
                }
            }
        },
        '& p': {
            width: '100%',
            margin: 0
        },
        '& > .imgGrid': {
            display: 'none'
        },
        '&:hover > .imgGrid': {
            display: 'block'
        }
    },
    chip: props => ({
        ...props.typeChip,
        marginBottom: spacing(.5)
    })
}));


let timer;

export const MediaLib = ({onMediaSelected, onMediaLibClose, config, hidden}) => {
    const dispatch = useDispatch(),
        theme = useTheme(),
        { ...mashupStyles } = useMashupStyles(theme),
        classes = useStyles(mashupStyles),
        { items, dmOnly } = config,
        [resourcesExpanded, setResourcesExpanded] = useState(true),
        [searchExpanded, setSearchExpanded] = useState(true),
        [resources, setResources] = useState(),
        [filteredResources, setFilteredResources] = useState(),
        [searchRes, setSearchRes] = useState(),
        [resourcesReady, setResourcesReady] = useState(false),
        [searchReady, setSearchReady] = useState(),
        [executedSearch, setExecutedSearch] = useState(''),
        [query, setQuery] = useState(''),
        [ownerFilter, setOwnerFilter] = useState(),
        [typeFilter, setTypeFilter] = useState(''),
        [sortFilter, setSortFilter] = useState(''),
        [orderFilter, setOrderFilter] = useState(''),
        [start, setStart] = useState(0),
        { data, fetching, error } = useSelector(state => state.resources),
        { uploadedId, status } = useSelector(state => state.video),
        { t } = useTranslation(['dm.article'], { useSuspense: false }),
        { data: searchData, fetching: searchFetching, error: searchError } = useSelector(state => state.dmSearch),
        { user, owner } = useSelector(state => state.header);

    useEffect(() => {
        dispatch(requestResources(owner.uniqueId, ''));
        setResourcesReady(false);
    }, [owner.uniqueId, dispatch]);

    useEffect(() => {
        if (fetching === null) {
            if (owner && owner.uniqueId) {
                dispatch(requestResources(owner.uniqueId));
            }
        } else if (!fetching && !error && data) {
            setResources(data);
            setFilteredResources(data.resources);
            setResourcesReady(true);
        }
    }, [dispatch, fetching, error, data, owner]);

    useEffect(() => {
        if (uploadedId && status === 'init') {
            dispatch(uploadVideoStatus(user.uniqueId, uploadedId));
        } else if (uploadedId && status === 'done') {
            dispatch(publishVideo(user.uniqueId, uploadedId));
        }
    }, [dispatch, uploadedId, status, user.uniqueId]);

    useEffect(() => {
        if (searchFetching || searchError) {
            // setExecutedSearch('');
        } else if (!searchFetching && !searchError && searchData) {
            setSearchRes(searchData);
            setSearchReady(true);
        }
    }, [dispatch, searchFetching, searchError, searchData, executedSearch]);

    useEffect(() => {
        if (start > 0) {
            setStart(0);
        } else if (resources) {
            executeSearch();
        }
    }, [query, ownerFilter, typeFilter, sortFilter, orderFilter]);

    useEffect(() => {
        if (resources) {
            executeSearch();
        }
    }, [start]);

    const uploadHandler = e => {
        dispatch(uploadMedia(e.target.files, user.uniqueId));
    };

    const uploadVideoHandler = e => {
        dispatch(uploadVideo(e.target.files, user.uniqueId));
    };

    const uploadDialogHandler = e => {
        dispatch(videoDialogDismissed());
    };

    const closeHandler = e => {
        onMediaLibClose && onMediaLibClose();
    };

    const searchResources = value => {
        const words = value.replace(' ', ',').split(',').filter(w => w !== '');
        let res = [];

        if (words.length) {
            try {
                resources.resources.forEach(r => {
                    const match = words.filter(q => r.filename.toLowerCase().includes(q.toLowerCase()));

                    if (match.length) {
                        res.push(r);
                    }
                });
            } catch (error) {
                console.warn(`Failed to search resources with query «${value}»`);
            }
        } else {
            res = resources.resources;
        }

        setFilteredResources(res);
    };

    const executeSearch = () => {
        if (timer) {
            clearTimeout(timer);
        } else {
            setSearchRes();
        }

        setSearchReady(false);

        timer = setTimeout(() => {
            const q = `${query}${ownerFilter ? ' owner:' + ownerFilter : ''}${typeFilter ? ' type:' + typeFilter : ''}${sortFilter ? ' sort:' + sortFilter : ''}${orderFilter ? ' sort_order:' + orderFilter : ''} start:${start}`;

            searchResources(query);

            if (q !== executedSearch) {
                console.log('setExecutedSearch: ', q);
                setExecutedSearch(q);
                dispatch(requestDmSearch(q, start === 0));
            } else {
                setSearchReady(true);
            }
        }, 2000);
    };

    const getPictureMeta = async (dimu_code, image_id) => {
        try {
            const response = await fetch(`${appConfig.get('articleApi')}media/meta/${dimu_code}/${image_id}`);
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                return await response.json();
            }
        } catch (e) {
            console.log(e);
        }
    };

    const mediaSelectedHandler = async (obj, selected, update = false) => {
        let img = { ...obj };

        if (!selected && img.artifactType.toLowerCase() !== 'photograph') {
            const meta = await getPictureMeta(img.dimuId, img.image_id);

            if (meta && meta.photographer) {
                img.local.credit = meta.photographer;
            }
        }

        onMediaSelected && onMediaSelected([img], selected, update);
    };

    const itemSelected = (item, compareProp = 'dimuId') => {
        const res = items && items.findIndex(x => x && x[compareProp] === item[compareProp]);

        if (res >= 0) {
            return items[res];
        }

        return false;
    };

    const renderMediaCard = item => {
        const obj = dmObjMapper(item),
            selected = itemSelected(obj, 'dimuId'),
            multipleImg = item.image_ids && item.image_ids.length > 1;

        const onClick = () => {
            mediaSelectedHandler(obj, selected);
        };

        const handleSelect = (selectedItem, remove = false) => {
            if (onMediaSelected) {
                const sObj = dmObjMapper(selectedItem);
                mediaSelectedHandler(sObj, remove ? sObj : false);
            }
        };

        return (
            <div
                key={obj.objectId}
                className={`${classes.mediaItem}${selected ? ' selected' : ''}`}
                onClick={!multipleImg ? onClick : undefined}
            >
                <ImageSelector items={items} item={item} dmOnly={dmOnly} selected={selected} multipleImg={multipleImg}
                               handleSelect={handleSelect}/>
                <div className="fieldWrapper" onClick={multipleImg ? onClick : undefined}>
                    <Chip className={classes.chip} size="small" variant="outlined" label={obj.artifactType}/>
                    <Chip className={classes.chip} size="small" variant="outlined" label={obj.objectId}/>
                    <p>{obj.title}</p>
                </div>
            </div>
        )
    };

    const renderResources = (item, index) => {
        if (typeof item === 'undefined') {
            return null;
        }

        const mappedItem = imageMapper(item),
            selected = itemSelected(mappedItem, 'image_id');

        const onClick = () => {
            onMediaSelected && onMediaSelected([mappedItem], selected);
        }

        const renderItem = item => {
            switch (item.type) {
                case 'video':
                    return (
                        <video width="240" height="180" controls onClick={onClick}>
                            <source src={mappedItem.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    );
                case 'document':
                    return (
                        <div className="document" onClick={onClick}>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg" alt="PDF" />
                            <span>{item.filename}</span>
                        </div>
                    );
                default:
                    return (
                        <>
                            {item.mimetype === 'image/svg+xml' && item.source
                                ? <img className="svg" src={svgToSrc(item.source)} onClick={onClick} alt={item.filename} />
                                : <img src={`${item.url}?dimension=167x167`} onClick={onClick} alt={item.filename} />
                            }
                        </>
                    );
            }
        };

        return (
            <div key={`${mappedItem.filename}-${index}`} className={`image--preview${selected ? ' selected' : ''}`}>
                {renderItem(mappedItem)}
            </div>
        );
    };

    return (
        <div style={hidden ? {display:'none'} : {}}>
            <div className={classes.header}>
                <Search className={classes.searchIcon}/>
                <input type="text" placeholder={t('mediaLib_search-placeholder', 'Finn media')}
                       onChange={e => setQuery(e.target.value)}/>
                <button onClick={closeHandler}><strong>«&nbsp;</strong>Lukk</button>
            </div>
            {!dmOnly && (
                <section className={`${classes.resourceSection}${resourcesExpanded ? ' expanded' : ''}`}>
                    <Accordion
                        component="div"
                        title={`${(filteredResources && filteredResources.length) || 0} ${t('mediaLib_resource-hits', 'treff i egne bilder')}`}
                        expanded={resourcesExpanded}
                        onToggle={() => setResourcesExpanded(!resourcesExpanded)}
                    >
                        <Input
                            accept="image/*"
                            className={classes.input}
                            id="button-image-upload"
                            type="file"
                            onChange={uploadHandler}
                        />
                        <label htmlFor="button-image-upload">
                            <Button variant="contained" startIcon={<CloudUploadIcon/>} color="default" component="span">
                                {t('mediaLib_upload', 'Last opp bilde')}
                            </Button>
                        </label>
                        <Input
                            accept="video/*"
                            className={classes.input}
                            id="button-video-upload"
                            type="file"
                            onChange={uploadVideoHandler}
                        />
                        <label htmlFor="button-video-upload">
                            <Button variant="contained" startIcon={<CloudUploadIcon/>} color="default" component="span">
                                {t('mediaLib_upload_video', 'Last opp video')}
                            </Button>
                        </label>
                        {!resourcesReady && !error && <ProgressIndicator/>}
                        <div className={classes.uploadedWrapper}>
                            {resourcesReady && resources.resources && (
                                filteredResources.map(renderResources)
                            )}
                        </div>
                    </Accordion>
                </section>
            )}
            {(error || searchError) && <Error/>}
            <section className={classes.searchSection}>
                <Accordion
                    component="div"
                    title={`${(searchRes && searchRes.count_found) || 0} ${t('mediaLib_dm-hits', 'treff i Digitaltmuseum')}`}
                    expanded={searchExpanded}
                    onToggle={() => setSearchExpanded(!searchExpanded)}
                >
                    <div className={classes.filters}>
                        <p>{t('search_title', 'Begrens søket til:')}</p>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={e => setOwnerFilter(e.target.checked ? user.selectedOwner.abbr : '') && executeSearch()}/>
                                }
                                /*label={`${t('search-filter_owner', 'Egen samling')} (${user.selectedOwner.abbr})`}*/
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id="artifactTypeLabel">{t('search-filter_type', 'Type')}</InputLabel>
                            <Select labelId="artifactTypeLabel" value={typeFilter}
                                    onChange={e => setTypeFilter(e.target.value)}>
                                {artifactTypes.map(at => <MenuItem key={at.value} value={at.value}>{at.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="sortLabel">{t('search-filter_sort', 'Sortering')}</InputLabel>
                            <Select labelId="sortLabel" value={sortFilter}
                                    onChange={e => setSortFilter(e.target.value)}>
                                {sort.map(s => <MenuItem key={s.value} value={s.value}>{s.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="orderLabel">{t('search-filter_order', 'Rekkefølge')}</InputLabel>
                            <Select labelId="orderLabel" value={orderFilter}
                                    onChange={e => setOrderFilter(e.target.value)}>
                                {order.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    {searchRes && searchRes.objects && searchRes.objects.map(renderMediaCard)}
                    {searchReady === false && !searchError && <ProgressIndicator/>}
                    {searchRes && searchRes.count_found > searchRes.count_returned && (
                        <Button variant="contained" className={classes.moreBtn} onClick={() => setStart(start + 30)}>
                            {t('mediaLib_show-more', {
                                count: searchRes.objects.length,
                                total: searchRes.count_found,
                                defaultValue: 'Hent flere treff (viser {{count}} av {{total}})'
                            })}
                        </Button>
                    )}
                </Accordion>
            </section>
            {['init', 'convert', 'converting', 'done', 'publishing', 'published', 'failed'].includes(status) && <UploadDialog status={status} closeHandler={uploadDialogHandler} />}
        </div>
    );
};
