import {
    CATEGORY_REQUESTED, CATEGORY_SUCCEEDED, CATEGORY_FAILED
} from '../actions/filter';

const initialState = {
    category_data: [],
    category_fetching: null,
    category_error: false
};

const category = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORY_REQUESTED:
            return {
                ...state,
                category_fetching: true,
                category_error: false
            };
        case CATEGORY_SUCCEEDED:
            return {
                category_data: action.data.resources,
                category_fetching: false,
                category_error: false
            };
        case CATEGORY_FAILED:
            return {
                ...state,
                category_data: [],
                category_fetching: false,
                category_error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default category;
