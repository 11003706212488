import {
    ARTICLES_REQUESTED, ARTICLES_SUCCEEDED, ARTICLES_FAILED
} from '../actions/article';
// import {act} from "@testing-library/react";

const initialState = {
    owner: null,
    articles: [],
    countFound: 0,
    countReturned: 0,
    fetching: null,
    error: false
}

const articleList = (state = initialState, action) => {
    switch (action.type) {
        case ARTICLES_REQUESTED:
            return {
                ...state,
                rows: null,
                fetching: true,
                error: false
            };
        case ARTICLES_SUCCEEDED:
            return {
                articles: action.data.articles,
                countFound: action.data.total,
                countReturned: Math.min(action.data.limit, action.data.articles.length),
                fetching: false,
                error: false
            };
        case ARTICLES_FAILED:
            return {
                ...state,
                rows: null,
                fetching: false,
                owner: action.owner,
                error: true
            };
        default:
            return {
                ...state
            }
    }
};

export default articleList;
