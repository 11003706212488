import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setUserAccess } from '../redux/actions/userAccess';

export const UserAccess = ({ children }) => {
    const dispatch = useDispatch(),
        { user } = useSelector(state => state.header);

    useEffect(() => {
        if (user.ownerUniqueId) {
            const owner = user.selectedOwner,
                dmApp = owner && owner.apps && owner.apps.find(a => a.id === '2cdc3799-bbc1-4a46-9529-2bdd7f3e0694'),
                role = dmApp && dmApp.role.name;
    
            if (role) {
                dispatch(setUserAccess({
                    root: ['Admin', 'Editor', 'User'].includes(role),
                    start: ['Admin', 'Editor', 'User'].includes(role),
                    statistics: ['Admin', 'Editor', 'User'].includes(role),
                    inbox: ['Admin', 'Editor'].includes(role),
                    article: ['Admin', 'Editor'].includes(role),
                    homepage: ['Admin', 'Editor'].includes(role)
                }));
            }
        }
    }, [dispatch, user.ownerUniqueId, user.selectedOwner]);
    
    return <>{children}</>;
};
