let initialState = {
    root: '',
    name: '',
    pathname: '',
    parents: [],
    languages: [],
    action: {
        expanded: false,
        title: '',
        subtitle: '',
        collapseUrl: ''
    },
    sidebar: {
        menu: []
    },
    content: {
        expanded: true
    },
    theme: {}
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_APP':
            return initialState;

        case 'RECEIVE_APP':
            return Object.assign({}, state, {
                root: action.root,
                name: action.name
            })

        case 'RECEIVE_PATHNAME':
            return Object.assign({}, state, {
                pathname: action.pathname,
            })

        case 'RECEIVE_PARENTS':
            return Object.assign({}, state, {
                parents: action.parents,
            })

        case 'RECEIVE_APP_LANGUAGES':
            return Object.assign({}, state, {
                languages: action.languages,
            })

        case 'RECEIVE_APP_HEADER':
            return Object.assign({}, state, {
                header: {
                    ...state.action,
                    title: action.title,
                    subtitle: action.subtitle
                }
            })

        case 'RECEIVE_APP_ACTION':
            return Object.assign({}, state, {
                action: {
                    ...state.action,
                    title: action.title,
                    subtitle: action.subtitle,
                    collapseUrl: action.collapseUrl
                }
            })

        case 'RECEIVE_APP_SIDEBAR':
            return Object.assign({}, state, {
                sidebar: action.sidebar
            })

        case 'RECEIVE_APP_BUTTON':
            return Object.assign({}, state, {
                sidebar: {
                    ...state.sidebar,
                    button: action.button
                }
            })

        case 'RECEIVE_APP_MENU':
            return Object.assign({}, state, {
                sidebar: {
                    ...state.sidebar,
                    menu: action.menu
                }
            })

        case 'REQUEST_APP_THEME':
            return Object.assign({}, state, {
                theme: {}
            })

        case 'RECEIVE_APP_THEME':
            return Object.assign({}, state, {
                theme: action.theme
            })

        case 'TOGGLE_APP_SECTION':
            return Object.assign({}, state, {
                [action.name]: {
                    ...state[action.name],
                    expanded: !state[action.name].expanded
                }
            })

        case 'EXPAND_APP_SECTION':
            return Object.assign({}, state, {
                [action.name]: {
                    ...state[action.name],
                    expanded: true
                }
            })

        case 'COLLAPSE_APP_SECTION':
            return Object.assign({}, state, {
                [action.name]: {
                    ...state[action.name],
                    expanded: false
                }
            })

        default:
            return state
    }
}

export default app;