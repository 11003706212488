import {
    IMAGE_DATA_UPDATE_REQUESTED, IMAGE_DATA_UPDATED, IMAGE_DATA_UPDATE_FAILED
} from '../actions/media';

const initialState = {
    data: null,
    fetching: null,
    error: false
};

export const imageData = (state = initialState, action) => {
    switch (action.type) {
        case IMAGE_DATA_UPDATE_REQUESTED:
            return {
                ...state,
                fetching: true,
            };
        case IMAGE_DATA_UPDATED:
            console.log("Resources reducer", action.data)
            return {
                ...state,
                data: action.data,
                fetching: false
            };
        case IMAGE_DATA_UPDATE_FAILED:
        default:
            return {
                ...state
            }
    }
};
