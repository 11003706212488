export default settings => {
    return {
        filter: `
        contrast(${settings.contrast}%)
        grayscale(${settings.grayscale}%)
        hue-rotate(${settings.hue}deg)
        brightness(${settings.brightness}%)
        saturate(${settings.saturate}%)
        sepia(${settings.sepia}%)
        invert(${settings.invert}%)`
    };
};
