import sections from './section/ownerSections';

export default {
    page_type: {
        type: 'string',
        default: 'owner',
        readOnly: true
    },
    sections: {
        type: 'array',
        title: null,
        items: sections
    }
};