import React, {useEffect} from 'react';
import { Header } from '@ekultur/header-state';
import { useTranslation } from "react-i18next";
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Theme } from "./Theme";

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        paddingTop: spacing(8)
    }
}));

export const Layout = ({ children }) => {
    const classes = useStyles(),
        [mobileOpen, setMobileOpen] = React.useState(false),
        { t, ready } = useTranslation(['navigation'], { useSuspense: false });

    return (
        <Theme>
            <Header
                mobileOpen={mobileOpen}
                mobileOpenHandler={setMobileOpen}
                translateHandler={({ key, defaultValue, options }) => t(key, defaultValue, options)}
            />
            <div className={classes.root}>
                {children}
            </div>
        </Theme>
    );
};
