import React, { useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Grid, Paper, IconButton, Icon } from '@material-ui/core';
import ToggleIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles(({spacing}) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        minHeight: spacing(7),
        userSelect: 'none',
        '&[aria-expanded=true]': {
            minHeight: spacing(8)
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    section: {
        minWidth: '400px',
        paddingRight: spacing(1),
        paddingLeft: spacing(.5),
        backgroundColor: '#fafafa',
        listStyleType: 'none',
        '& & > .MuiCollapse-entered': {
            paddingBottom: spacing(1)
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        flexBasis: '100%',
        marginRight: spacing(1)
    },
    title: {
        fontFamily: 'Akkurat, sans-serif',
        fontWeight: 'normal',
        fontSize: props => props && props.fontSize ? props.fontSize : '16px',
        letterSpacing: 0,
    },
    description: {
        fontFamily: 'Akkurat, sans-serif',
        fontSize: '14px',
        fontWeight: 'normal',
        letterSpacing: 0,
        opacity: '.6',
        marginLeft: spacing(1)
    },
    toggle: {
        transition: '.125s ease-in-out',
        transform: 'rotate(0deg)',

        '&[aria-expanded=true]': {
            transition: '.125s ease-in-out',
            transform: 'rotate(90deg)'
        }
    },
    actionGrid: {
        flex: '0 0 auto'
    },
    button: {
        margin: '-4px'
    },
    icon: {
        fontSize: props => props && props.fontSize ? props.fontSize : '18px'
    },
    collapseContainer: {
      paddingRight: spacing(1),
      paddingLeft: spacing(1)
    }
}));

export const Accordion = ({
    id,
    title,
    description,
    children,
    expanded,
    onToggle,
    collapsible = true,
    actions = null,
    settings = null,
    component = 'li',
    untitled = "Untitled",
    styles = {}
}) => {
    const [open, setOpen] = useState(expanded),
        classes = useStyles(styles);
    
    useEffect(() => {
        if (open !== expanded) {
            setOpen(!open);
        }
    }, [expanded]);

    const renderActionToolbar = () => {
        const { disabled, readonly, index, hasMoveUp, hasMoveDown, hasRemove, onReorderClick, onDropIndexClick } = actions;

        return (
            <Grid key="actions" className={classes.actionGrid} item={true}>
                {(hasMoveUp || hasMoveDown) && (
                    <IconButton key="up" className={classes.button} disabled={disabled || readonly || !hasMoveUp} onClick={onReorderClick && onReorderClick(index, index - 1)}>
                        <Icon className={classes.icon}>arrow_upward</Icon>
                    </IconButton>
                )}

                {(hasMoveUp || hasMoveDown) && (
                    <IconButton key="down" className={classes.button} disabled={disabled || readonly || !hasMoveDown} onClick={onReorderClick && onReorderClick(index, index + 1)}>
                        <Icon className={classes.icon}>arrow_downward</Icon>
                    </IconButton>
                )}

                {hasRemove && (
                    <IconButton key="remove" className={classes.button} disabled={disabled || readonly} onClick={onDropIndexClick && onDropIndexClick(index)}>
                        <Icon className={classes.icon}>remove_circle</Icon>
                    </IconButton>
                )}
            </Grid>
        )
    };

    const toggleOpen = () => {
        setOpen(!open);
        onToggle && onToggle();
    }

    return (
        <Paper component={component} className={classes.section} elevation={open ? 4 : 1} square={true}>
            <header id={id} className={classes.root} aria-expanded={open}>
                <div className={classes.content} data-collapsible={collapsible} onClick={toggleOpen}>
                    <IconButton className={classes.toggle} color="inherit" aria-label="toggle" aria-expanded={open}>
                        <ToggleIcon />
                    </IconButton>
                    <Typography className={classes.title} component="h2" noWrap>{title || untitled}</Typography>
                    {description && <Typography className={classes.description} component="h3" noWrap>{description}</Typography>}
                </div>
                {settings}
                {(actions && actions.hasToolbar) &&
                    renderActionToolbar()
                }
            </header>
            <Collapse className={classes.collapseContainer} in={open}>
                {children}
            </Collapse>
        </Paper>
    );
};
