import React from 'react';
// import { useHistory } from "react-router-dom";
import { Button } from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { ReactComponent as SvgEidsvoll1814Logo } from "./frame.svg";

const kunnskapsDepLogo = "https://images.finncdn.no/dynamic/1600w/logo/logo/134765051/20200603/970b0c40-5476-4716-bbc4-42627b196208";


const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        marginTop: 'auto'
    },
    upperFooter: {
        background: '#333333',
        /* maxWidth: 1440,*/
        height: 104,
       /* marginTop: 78,*/
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            height: 136,
        },
        '& div:last-child': {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            '& a': {
                color: '#FFFFFF',
                fontSize: 18,
                textDecoration: 'none',
                marginRight: 24
            },
            [theme.breakpoints.down('xs')]: {
                marginLeft: 24,
                marginBottom: 24,
                justifyContent: 'flex-start',
            },
        },
        '& div:first-child': {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 35,
            '& > *': {
                color: '#FFFFFF',
            },
            [theme.breakpoints.down('xs')]: {
                marginLeft: 12,
                marginTop: 17,
                justifyContent: 'flex-start',
            },
        }
    },
    lowerFooter: {
        background: '#CBD0D1',
        height: 104,
        display: 'flex',
        '@media (min-width: 280px) and (max-width: 320px)' : {
            height: 246,
            flexDirection: 'column'
        },
        '@media (min-width: 321px) and (max-width: 1024px)' : {
            height: 200,
            flexDirection: 'column'
        },
        '& div:first-child': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: 10,
            '& .svgLogo': {
                [theme.breakpoints.down('xs')]: {
                    width: 151,
                    height: 53,
                },
            },
            '& > *': {
                marginLeft: 52,
                [theme.breakpoints.down('xs')]: {
                    marginLeft: 25,
                },
            },
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
            },
            '& .image': {
                width: 192,
                height: 107,
                [theme.breakpoints.down('sm')]: {
                    width: 151,
                    height: 53,
                },
            }
        },
        '& div:last-child': {
            '& p':{
                alignSelf: 'center',
                marginLeft: 52,
                [theme.breakpoints.down('xs')]: {
                    maxWidth: 216,
                    marginLeft: 10,
                },

            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexGrow: 1,
            '& .scrollTop': {
                flexGrow: 1,
                marginRight: 24
            },
            '& button': {
                backgroundColor: 'white',
                display: 'block',
                width: '64px',
                height: '64px',
                lineHeight: '77px',
                textDecoration: 'none',
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '50%',
                boxShadow: '10px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
                /* marginRight: 16,*/
            },
            [theme.breakpoints.up('xs')]: {
                paddingLeft: 15,
                flex: 1
            },
            '@media (max-width: 320px)' : {
                paddingLeft: 0,
            },
        }

    },

}));

export const Footer = () => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <div className={classes.upperFooter}>
                <div>
                    <IconButton onClick={() => { window.open("https://www.facebook.com/minstemme.no") }}><FacebookIcon /></IconButton>
                    <IconButton onClick={() => { window.open("https://twitter.com/minstemme") }}><TwitterIcon /></IconButton>
                    <IconButton><InstagramIcon /></IconButton>
                    <IconButton><RssFeedIcon /></IconButton>
                </div>
                <div>
                    <a href="/portal">Om nettstedet</a>
                    <a href="/portal">Kontakt</a>
                </div>
            </div>
            <div className={classes.lowerFooter}>
                <div>
                    <SvgEidsvoll1814Logo className="svgLogo" />
                    <img alt="Logo" className="image" src={kunnskapsDepLogo} />
                </div>
                <div>
                    <p>Mi stemme er ein del av Eidsvoll 1814.<br /> Mi stemme er utvikla av
                        KulturIT på oppdrag frå Kunnskapsdepartmentet.</p>
                    <div className="scrollTop">
                        <Button onClick={() => { window.scrollTo(0, 0) }}>
                            <ArrowUpwardIcon style={{ color: '#005C72', width: 30, height: 30 }} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
