import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative",
        paddingRight: '0 !important',
        backgroundColor: '#006C85',
        '& h6': {
            flex: 1
        }
    }
}));

const statusMapping = {
    init: 'Laster opp...',
    convert: 'Venter på prosessering',
    converting: 'Prosesseres',
    done: 'Ferdig',
    publishing: 'Publiserer',
    published: 'Videoen er lagt til i mediebiblioteket og klar til bruk',
    failed: 'Opplasting feilet'
};


export const UploadDialog = ({ status, closeHandler }) => {
    const classes = useStyles();

    const onClose = () => {
        (['published', 'failed'].includes(status) && closeHandler) && closeHandler();
    };

    return (
        <Dialog
            open={true}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6">
                        {status !== 'published'
                            ? 'Laster opp video. Vennligst ikke lukk vinduet!'
                            : 'Ferdig!'
                        }
                    </Typography>
                    {['published', 'failed'].includes(status) && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <Box m={3}>
                {status && <Typography>Status: {statusMapping[status]}</Typography>}
            </Box>
        </Dialog>
    );
};
