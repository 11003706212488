import React from 'react';
import { useTranslation } from "react-i18next";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTheme } from '@material-ui/core/styles';
import { ListItem, Typography, Chip, CardMedia } from '@material-ui/core';

import useMashupStyles from '../Mashup/MashupStyles';

const useStyles = makeStyles(({spacing}) => ({
    typeChip: props => ({ ...props.typeChip }),
    meta: props => ({ ...props.meta, marginTop: 0 }),
    message: props => ({ ...props.message, fontSize: '.9rem', fontWeight: 'bold' }),
    listItem: props => ({ ...props.listItem, flexFlow: 'nowrap' }),
    cardContent: {
        position: 'absolute',
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        paddingLeft: '85px',
        paddingRight: spacing(2)
    },
    leadText: {
        height: '1.2rem',
        fontSize: '.8rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    media: {
        minWidth: '75px',
        marginTop: 0,
        marginRight: spacing(1),
        height: 0,
        paddingBottom: '75px',
        backgroundColor: '#ddd',
        position: 'relative',
        '&.dm-icon': {
            backgroundSize: 'auto'
        }
    }
}));

export const ArticleListCard = ({ article, onSelect }) => {
    const theme = useTheme(),
        { ...mashupStyles } = useMashupStyles(theme),
        classes = useStyles(mashupStyles),
        { t } = useTranslation(['dm.articleTypes'], { useSuspense: false }),
        created = new Date(article.createdAt),
        dateTime = `${created.toLocaleDateString()} ${created.toLocaleTimeString()}`;
        let headerImg = article.headerImage || 'https://pdms.dimu.org/image/02349w5hFUHV?mediaType=image/svg';

    if (headerImg && headerImg.indexOf('png') > -1) {
        headerImg += '&mediaType=image/png';
    }

    const handleSelect = id => {
        onSelect && onSelect(id);
    };

    return (
        <ListItem
            button
            component="li"
            className={classes.listItem}
            onClick={() => handleSelect(article.id)}
        >
            <CardMedia
                className={`${classes.media}${!headerImg ? ' dm-icon' : ''}`}
                image={headerImg ? headerImg : 'https://pdms.dimu.org/image/02349w5hFUHV?mediaType=image/svg'}
            />
            <div className={classes.cardContent}>
                <div className={classes.meta}>
                    <Chip size="small" variant="outlined" label={t(`dm.articleTypes:${article.type}`)} className={classes.typeChip} />
                    <Chip size="small" variant="outlined" label={t(`dm.article:${article.status}`)} className={classes.typeChip} />
                    {article.createdAt && <Typography component={'span'} >{dateTime} {t('dm.article:by', 'av')} {article.createdBy}</Typography>}
                </div>
                <Typography className={classes.message}>
                    {article.title}
                </Typography>
                <Typography noWrap className={classes.leadText}>
                    {article.leadText}
                </Typography>
            </div>
        </ListItem>
    );
};
